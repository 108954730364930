import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import { Button } from '@/components/ui/button';
import { EProjectStatus } from '@/enums';
import Statistics from './overview_content/Statistics';
import {
  translateNoSummaryData,
  translateSummary,
  translateQuestions,
  translateNoQuestionsData,
  translateKeyThemes,
  translateNoKeyThemesData,
  translateInterestingQuotes,
  translateNoQuotesData,
  translateOpportunities,
  translateNoOpportunitiesData,
} from './project_results/resultsPageTranslations';
import QuestionAnswerAccordion from '@/components/projects/single-project/research-design/components/QuestionAnswerAccordion';
import InterestingQuotes from '@/components/QuestionItem/InterestingQuotes';
import useTranslation from '@/hooks/useTranslation';
import { OverviewProps, QuestionSummary } from '@/types';
import Summary from '@/components/projects/single-project/project-summary/ProjectSummary';
import ProjectQuestionInsights from '@/components/QuestionItem/ProjectQuestionInsights';
import { resolveTranslation } from '@/utils/resolveTranslation';

function Overview({
  isPendingReRun,
  analysisLanguage,
  singleProjectData,
  newConversationsCount,
  onReRun,
  isRerunLoading,
  newConversationsState,
  rerunFilter,
  isLoadingBeforeApplyFilter,
}: OverviewProps) {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const { currentTheme } = useTheme();
  const { lang } = useTranslation();
  const { data: overviewData, isLoading: isOverviewLoading } = useQuery({
    queryFn: () =>
      axiosInstanceAnalyze.get(
        `/analyzer/results/${project_id}/overall${filter_id ? `?filter_id=${filter_id}` : ''}`,
      ),
    queryKey: ['overall_data', { filter_id }],
    select: (data) => data?.data,
    refetchOnWindowFocus: true,
    staleTime: 2000,
  });

  const questionSummary: QuestionSummary[] = overviewData?.question_summaries;
  const sortedQuestions = questionSummary?.every((item) => typeof item.index === 'number')
    ? questionSummary?.sort((a, b) => a.index - b.index)
    : questionSummary;

  const noEnoughInterviewData =
    overviewData?.statistics === null &&
    overviewData?.summary?.length === 0 &&
    overviewData?.themes?.length === 0 &&
    overviewData?.quotes?.length === 0 &&
    overviewData?.opportunities?.length === 0;
  const isForNewProjectsData = !!questionSummary?.length;
  const isLoading = isPendingReRun || isOverviewLoading;
  const isQuestionsAccordionPanelVisible = questionSummary?.length !== 0 && !!questionSummary;
  const isPendingReRunOrReRunLoading = isPendingReRun || isRerunLoading;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;

  const hasQuotesData = !!overviewData?.quotes?.length;
  const keyThemesTitle = resolveTranslation(translateKeyThemes, analysisLanguage);
  const opportunitiesTitle = resolveTranslation(translateOpportunities, analysisLanguage);
  const isApplyingFilter = (rerunFilter || isRerunLoading) && Boolean(filter_id);
  const isRunning = (!filter_id || (filter_id && isRerunLoading)) && !rerunFilter;
  const messageKey = isApplyingFilter ? 'msg.applyingFilter' : isRunning ? 'msg.running' : '';
  const noInterviewResults =
    isProjectCompleted && !!filter_id && !overviewData?.statistics && !rerunFilter;

  useEffect(() => {
    if (
      noEnoughInterviewData ||
      newConversationsState === 'done' ||
      !newConversationsState ||
      !overviewData
    )
      return;

    if (rerunFilter && newConversationsState === 'idle') {
      localStorage.removeItem(`rerun_filter_${filter_id}`);
    }
  }, [noEnoughInterviewData, rerunFilter, filter_id, newConversationsState, overviewData]);

  if (isOverviewLoading || isLoadingBeforeApplyFilter) return <FullPageLoader />;

  return noEnoughInterviewData ? (
    <div className="flex flex-col items-center justify-center w-full h-full min-h-[65vh] text-center">
      {!isProjectCompleted && (
        <Button
          variant="default"
          disabled={isPendingReRunOrReRunLoading}
          className="relative flex items-center gap-2 mt-5 font-bold px-7"
          onClick={() => onReRun()}
        >
          {isPendingReRunOrReRunLoading ? (
            <IconLoader2 className="animate-spin" size={20} />
          ) : (
            <PaperAirplaneIcon className="size-4" />
          )}
          {isPendingReRunOrReRunLoading ? 'Running...' : 'Run'}
          {!isRerunLoading && newConversationsCount > 0 && (
            <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
              {newConversationsCount}
            </span>
          )}
        </Button>
      )}
      {isProjectCompleted && isRerunLoading && (
        <Button
          variant="default"
          disabled
          className="flex items-center gap-2 mr-[4%] font-bold px-7"
        >
          <IconLoader2 className="animate-spin" size={20} />
          <p className="epilogue-font">{lang.get(messageKey)}</p>
        </Button>
      )}
      {noInterviewResults && (
        <p className="font-medium">{lang.get('msg.noInterviewsThatMatchThisFilter')}</p>
      )}
    </div>
  ) : (
    <div
      style={{ backgroundColor: currentTheme?.background }}
      className="flex flex-col items-center justify-center w-full gap-16"
    >
      <Statistics data={overviewData?.statistics} isLoading={isLoading} project_id={project_id} />
      {/* summary */}
      <Summary
        title={translateSummary[analysisLanguage] || translateSummary.English}
        marginBottom="!mb-6"
        summary={overviewData?.summary}
        isLoading={isLoading}
        noSummarydata={resolveTranslation(translateNoSummaryData, analysisLanguage)}
      />

      {/* questions */}
      {isForNewProjectsData && (
        <div className="flex flex-col w-full">
          <div className="flex items-center justify-between w-full">
            <h3
              style={{ color: currentTheme?.primary }}
              className="!mb-6 text-lg font-bold epilogue-font"
            >
              {resolveTranslation(translateQuestions, analysisLanguage)}
            </h3>
          </div>
          {isQuestionsAccordionPanelVisible ? (
            <QuestionAnswerAccordion data={sortedQuestions} />
          ) : (
            <p className="w-full text-left text-[15px]  mx-auto">
              {resolveTranslation(translateNoQuestionsData, analysisLanguage)}
            </p>
          )}
        </div>
      )}

      {/* -- OLD OVERVIEW DATA -- */}
      {!isForNewProjectsData && (
        <div className="flex flex-col items-center justify-center w-full gap-16">
          {/* key themes */}
          <ProjectQuestionInsights
            title={keyThemesTitle}
            data={overviewData?.themes}
            isLoading={isLoading}
            noDataText={resolveTranslation(translateNoKeyThemesData, analysisLanguage)}
          />
          {/* quotes */}
          <div className="flex flex-col items-center justify-center w-full">
            <div className="flex items-start justify-between w-full ">
              <h3
                style={{ color: currentTheme?.primary }}
                className="!mb-6 text-lg font-bold epilogue-font"
              >
                {resolveTranslation(translateInterestingQuotes, analysisLanguage)}
              </h3>
            </div>
            <div className="flex items-center justify-center w-full">
              {hasQuotesData ? (
                <InterestingQuotes
                  analysisLanguage={analysisLanguage}
                  data={overviewData?.quotes}
                  isLoading={isLoading}
                />
              ) : (
                <p className="w-full text-left text-[15px]  mx-auto">
                  {resolveTranslation(translateNoQuotesData, analysisLanguage)}
                </p>
              )}
            </div>
          </div>

          {/* opportunities */}
          <ProjectQuestionInsights
            data={overviewData?.opportunities}
            title={opportunitiesTitle}
            isLoading={isLoading}
            noDataText={resolveTranslation(translateNoOpportunitiesData, analysisLanguage)}
          />
        </div>
      )}
    </div>
  );
}

export default Overview;
