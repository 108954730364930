import { useQueryClient } from '@tanstack/react-query';
import { IconChevronLeft, IconChevronRight, IconDownload, IconLoader2 } from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ConversationMessage from '@/components/chat/ConversationMessage';
import formatDate from '@/utils/dateFormat';
import { Button } from '@/components/ui/button';
import {
  useMutateDownloadConversationAsCSV,
  useMutateGenerateTranscriptsTranslation,
} from '@/reactQuery/post';
import { toast } from '@/components/ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import truncateText from '@/utils/truncateText';
import calculateConversationDuration from '@/utils/calculateConversationDuration';
import TranscriptsTranslationDropdown from './transcripts_table_data/TranscriptsTranslationDropdown';
import languagesList from '@/lang/languages/languagesList';
import ConfirmTranslationModal from '@/components/Modals/ConfirmTranslationModal';
import { TranscriptProps } from '@/types';
import useTranslationsLoadingStore from '@/store/TranslationLoadingStore';
import { useGetTranslatedConversations } from '@/reactQuery/get';

const MAX_SUMMARY = 80;

function SingleTranscriptData({
  selectedTranscript,
  allConversationsLength,
  allConversations,
  analysisLanguage,
  languages,
  project_id,
  initialTranslatedLanguages,
  isLanguageForGoodTranscripts,
}: TranscriptProps) {
  const { lang } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: mutateAsyncDownloadAsCSV, isPending: isDownloadPending } =
    useMutateDownloadConversationAsCSV();
  const [isExpanded, setIsExpanded] = useState(false);
  const filter_id = searchParams.get('filter_id');
  const [prevAndNextBtnDisabled, setPrevAndNextBtnDisabled] = useState(false);
  const conversation_id = searchParams.get('selected_transcript');
  const activeLanguage = searchParams.get('lang') || initialTranslatedLanguages[0];
  const [dropdownLanguage, setDropdownLanguage] = useState<string | null>(null);
  const [translatedLanguages, setTranslatedLanguages] = useState<string[]>(
    initialTranslatedLanguages,
  );
  const {
    setSingleConversationTranslationLoading,
    translationsLoading,
    setTranslationsLoading,
    singleConversationTranslationLoading,
    setTranslating,
    isTranslating,
    setSingleConversationsBtnsDisabled,
    singleConversationsBtnsDisabled,
    setTranslatingProjects,
    translatingProjects,
    originalTranscripts,
  } = useTranslationsLoadingStore();
  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [unTranslatedLanguages, setUntranslatedLanguages] = useState<string[]>(
    languagesList.language
      .map(({ label }) => label)
      .filter(
        (language) =>
          !languages.includes(language) &&
          language !== activeLanguage &&
          language !== analysisLanguage,
      ),
  );
  const [participant, setParticipant] = useState('');
  const hasFoundTranscriptWhileLoading = useRef(false);
  const isEnabledFetch = useRef(true);
  const { mutateAsync } = useMutateGenerateTranscriptsTranslation(project_id);
  const { data, refetch: refetchSingleConversations } = useGetTranslatedConversations(
    project_id,
    conversation_id,
    allConversations,
    searchParams.get('lang') || '',
    translationsLoading,
    isEnabledFetch.current,
  );

  const currentProjectIsTranslating = translatingProjects?.find(
    (project) => project.project_id === project_id,
  );

  const current = activeLanguage ? data?.current : data;
  const prev = activeLanguage ? data?.prev : null;
  const next = activeLanguage ? data?.next : null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const downloadCSV = ({ conversation_data, id }: { conversation_data: any; id: string }) => {
    const blob = new Blob([conversation_data], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `tellet_conversation_${id}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downlaodConversationAsCSV = async () => {
    try {
      const result = await mutateAsyncDownloadAsCSV({
        conversation_id: selectedTranscript?._id as string,
      });
      const conversationData = result.data;
      downloadCSV({ conversation_data: conversationData, id: selectedTranscript?._id as string });
      toast({ description: lang.get('msg.conversationSuccessfullyDownloaded') });
    } catch (err) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
    }
  };

  const removeSelectedTranscriptAndGoBack = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('selected_transcript');
    setSearchParams(newSearchParams);
  };
  const transcript = current?.data;

  const handleTranscriptNavigation = async (direction: 'next' | 'prev') => {
    const transcript = direction === 'next' ? next : prev;

    if (isTranslating && !transcript) {
      const { data } = await refetchSingleConversations();
      const newTranscript = data?.[direction]?.data?.conversation_id;

      if (!newTranscript) return;

      setParticipant(newTranscript);
      searchParams.set('selected_transcript', newTranscript);
      setSearchParams(searchParams);
      return;
    }

    if (!transcript) return;

    setParticipant(transcript);
    searchParams.set(
      'selected_transcript',
      searchParams.get('lang') ? transcript.data.conversation_id : transcript.data._id,
    );
    setSearchParams(searchParams);
  };

  const handleNextTranscript = () => handleTranscriptNavigation('next');
  const handlePrevTranscript = () => handleTranscriptNavigation('prev');

  const handleLanguageSelection = async (language: string) => {
    if (translatedLanguages.includes(language) || language === initialTranslatedLanguages[0]) {
      const newSearchParams = new URLSearchParams(searchParams);

      if (language === initialTranslatedLanguages[0]) {
        newSearchParams.delete('lang');
      } else {
        newSearchParams.set('lang', language);
      }
      setSearchParams(newSearchParams);
      setIsLoading(true);
    } else {
      isEnabledFetch.current = false;
      setDropdownLanguage(language);
      setIsModalOpen(true);
    }
  };

  const confirmTranslation = async () => {
    if (!dropdownLanguage) return;
    setIsModalOpen(false);
    setTranslating(true);
    toast({ description: lang.get('msg.translationMightTakeAWhile') });
    setTranslationsLoading(true);
    setPrevAndNextBtnDisabled(true);
    setSingleConversationsBtnsDisabled(true);
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('lang', dropdownLanguage);
    setSearchParams(newSearchParams);
    setTranslatingProjects([
      ...translatingProjects,
      { project_id, language: dropdownLanguage, length: allConversationsLength },
    ]);
    try {
      await mutateAsync({ languages: [dropdownLanguage] });
    } catch (error) {
      toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      setTranslating(false);
      setSingleConversationTranslationLoading(false);
    }
  };

  useEffect(() => {
    if (languages.length && !isTranslating) {
      setTranslatedLanguages((prev) => {
        const newLanguages = languages.filter((language) => !prev.includes(language));
        return [...prev, ...newLanguages];
      });

      setUntranslatedLanguages(
        languagesList.language
          .map(({ label }) => label)
          .filter(
            (language) =>
              !languages.includes(language) &&
              language !== activeLanguage &&
              language !== analysisLanguage,
          ),
      );
    }
  }, [languages, activeLanguage, analysisLanguage, isTranslating]);

  useEffect(() => {
    if (transcript) {
      setIsLoading(false);
    }
  }, [transcript]);

  const getSortedTranslatedLanguages = () => {
    return [
      initialTranslatedLanguages[0],
      ...translatedLanguages
        .filter((lang) => lang !== 'Original')
        .sort((a, b) => a.localeCompare(b)),
    ];
  };

  useEffect(() => {
    if (current) {
      setParticipant(current);
    }
  }, [current]);

  const isLastInTheConversationList =
    allConversations[allConversations.length - 1]?._id === conversation_id ||
    allConversations[allConversations.length - 1]?.conversation_id === conversation_id;
  const isFirstInTheConversationList =
    allConversations[0]?._id === conversation_id ||
    allConversations.length === 1 ||
    allConversations[0]?.conversation_id === conversation_id;

  useEffect(() => {
    if (!currentProjectIsTranslating || !isLanguageForGoodTranscripts) return;
    if (currentProjectIsTranslating && !!allConversationsLength) {
      setTranslationsLoading(false);
      setSingleConversationTranslationLoading(false);
    }
    if (
      allConversations?.some((item) => item?.conversation_id === conversation_id) &&
      !hasFoundTranscriptWhileLoading.current
    ) {
      refetchSingleConversations();
      setSingleConversationTranslationLoading(false);
      hasFoundTranscriptWhileLoading.current = true;
      isEnabledFetch.current = true;
      setPrevAndNextBtnDisabled(false);
      setSingleConversationsBtnsDisabled(false);
    }

    if (currentProjectIsTranslating?.length === allConversationsLength) {
      queryClient.invalidateQueries([
        'single_conversations_data',
        { project_id, filter_id, language: dropdownLanguage },
      ]);
      queryClient.invalidateQueries(['languages']);
      toast({ description: lang.get('msg.translationCompleted') });
      setTranslating(false);
      const translatedProjects = translatingProjects.filter(
        (project) => project.project_id !== project_id,
      );
      setTranslatingProjects([...translatedProjects]);
      refetchSingleConversations();
      setPrevAndNextBtnDisabled(false);
      setSingleConversationsBtnsDisabled(false);
    }
  }, [allConversationsLength, currentProjectIsTranslating, isLanguageForGoodTranscripts]);

  const selectedConversationTranscript = searchParams.get('lang')
    ? originalTranscripts?.find((item) => item?._id === conversation_id)
    : transcript;

  return (
    <>
      <div className="flex flex-col w-full">
        <div className="flex items-center justify-between w-full mb-4 pt-4">
          <button
            onClick={removeSelectedTranscriptAndGoBack}
            type="button"
            className="hover:font-semibold flex items-center gap-1.5"
          >
            <IconChevronLeft size={18} className="mt-0.5" />
            {lang.get('msg.goBack')}
          </button>
          <div className="flex items-center gap-4">
            <TranscriptsTranslationDropdown
              isLoading={!!currentProjectIsTranslating}
              activeLanguage={activeLanguage! || dropdownLanguage}
              translatedLanguages={getSortedTranslatedLanguages()}
              unTranslatedLanguages={unTranslatedLanguages}
              handleLanguageSelection={handleLanguageSelection}
            />
            <div className="flex items-center bg-white rounded-lg text-dark-text">
              <button
                disabled={
                  isFirstInTheConversationList ||
                  prevAndNextBtnDisabled ||
                  singleConversationsBtnsDisabled
                }
                type="button"
                className="flex items-center justify-center border border-black rounded-l-lg disabled:cursor-not-allowed h-9 w-9"
                onClick={handlePrevTranscript}
              >
                <IconChevronLeft size={18} />
              </button>
              <div className="flex items-center min-w-[120px] justify-center px-3 text-sm font-semibold border-t border-b border-black h-9">
                {isLoading ? (
                  <IconLoader2 className="animate-spin" size={18} />
                ) : (
                  <div className="flex items-center gap-1.5">
                    <span>{lang.get('msg.participant')} </span>
                    <span>
                      {participant?.data?.respondent_index ?? participant?.respondent_index}
                    </span>
                  </div>
                )}
              </div>
              <button
                disabled={
                  isLastInTheConversationList ||
                  prevAndNextBtnDisabled ||
                  singleConversationsBtnsDisabled
                }
                type="button"
                className="flex items-center justify-center border border-black rounded-r-lg disabled:cursor-not-allowed h-9 w-9"
                onClick={handleNextTranscript}
              >
                <IconChevronRight size={18} />
              </button>
            </div>
            {!searchParams.get('lang') && (
              <Button
                disabled={isDownloadPending}
                variant="default"
                onClick={downlaodConversationAsCSV}
                className="flex items-center gap-1 text-sm"
              >
                {isDownloadPending ? (
                  <IconLoader2 className="animate-spin" size={18} />
                ) : (
                  <IconDownload size={18} />
                )}
                {lang.get('msg.export')}
              </Button>
            )}
          </div>
        </div>
        {!singleConversationTranslationLoading && transcript && (
          <div className="flex w-full gap-5 md800:flex-col-reverse">
            <div className="relative flex w-full overflow-hidden border rounded-tr-lg rounded-br-lg shadow-sm border-dark-text/10">
              {/* chat */}
              <div className="flex flex-col w-full h-full p-3 max-h-[600px] bg-secondary-background">
                <div className="relative flex flex-col w-full h-full px-2 overflow-hidden overflow-y-auto">
                  <div className="flex flex-col w-full gap-4 pb-2 mt-8">
                    {transcript?.messages?.map((item) => {
                      return (
                        <ConversationMessage
                          key={item.text + item.role + item.created_at}
                          {...item}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {/* info data */}
            <div className="w-[500px] text-dark-text max-h-[600px] overflow-y-auto md800:w-full h-full bg-secondary-background shadow-sm border border-dark-text/10 rounded-lg p-4">
              <div className="flex flex-col gap-3">
                <ul className="flex flex-col text-sm gap-0.5">
                  <li className="font-bold">{lang.get('msg.conversationID')}:</li>
                  <li className="font-normal">
                    {selectedConversationTranscript?.conversation_id ??
                      selectedConversationTranscript?._id}
                  </li>
                </ul>
                <ul className="flex flex-col text-sm gap-0.5">
                  <li className="font-bold">{lang.get('msg.createdAt')}:</li>
                  <li className="font-normal">
                    {formatDate(
                      (selectedConversationTranscript?.createdAt ??
                        selectedConversationTranscript?.conversationCreatedAt) as string,
                      'DD/MMM/YYYY - hh:mm:ss A',
                    )}
                  </li>
                </ul>
                {selectedConversationTranscript?.status && (
                  <ul className="flex flex-col text-sm gap-0.5">
                    <li className="font-bold">{lang.get('msg.status')}:</li>
                    <li className="font-normal capitalize">
                      {selectedConversationTranscript?.status}
                    </li>
                  </ul>
                )}
                {selectedConversationTranscript?.startedAt &&
                  selectedConversationTranscript?.finishedAt && (
                    <ul className="flex flex-col text-sm gap-0.5">
                      <li className="font-bold">Completion time:</li>
                      <li className="font-normal">
                        {calculateConversationDuration({
                          startedAt: selectedConversationTranscript?.startedAt as string,
                          finishedAt: selectedConversationTranscript?.finishedAt as string,
                        })}
                      </li>
                    </ul>
                  )}
                {selectedConversationTranscript?._id &&
                  selectedConversationTranscript?.metadata && (
                    <ul className="flex flex-col text-sm gap-0.5">
                      <li className="font-bold">{lang.get('msg.metadata')}:</li>
                      <li className="font-normal capitalize">
                        {Object?.entries(
                          (!!selectedConversationTranscript?._id &&
                            selectedConversationTranscript?.metadata) ??
                            {},
                        )?.map(([key, value]) => (
                          <div className="flex font-medium whitespace-nowrap" key={key}>
                            <p className="capitalize">{key}</p>:
                            <p className="ml-1">{String(value)}</p>
                          </div>
                        ))}
                      </li>
                    </ul>
                  )}
                <ul className="flex flex-col text-sm gap-0.5">
                  <li className="font-bold">{lang.get('msg.totalMessages')}:</li>
                  <li className="font-normal capitalize">{transcript?.messages?.length}</li>
                </ul>
                {selectedConversationTranscript?.digest?.summary && (
                  <ul className="flex flex-col text-sm gap-0.5">
                    <li className="font-bold">Conversation summary:</li>
                    <li className="font-normal">
                      {isExpanded
                        ? selectedConversationTranscript?.digest?.summary
                        : truncateText(
                            selectedConversationTranscript?.digest?.summary as string,
                            MAX_SUMMARY,
                          )}
                      {selectedConversationTranscript?.digest?.summary?.split(' ')?.length >
                        MAX_SUMMARY && (
                        <button
                          type="button"
                          className="ml-1 text-blue-500 hover:underline"
                          onClick={() => setIsExpanded(!isExpanded)}
                        >
                          {isExpanded ? 'Show less' : 'Show more'}
                        </button>
                      )}
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        )}
        {(singleConversationTranslationLoading || translationsLoading || !transcript) && (
          <div className="flex items-center justify-center w-full h-full min-h-[600px]">
            <IconLoader2 className="animate-spin" size={30} />
          </div>
        )}
      </div>
      <ConfirmTranslationModal
        isOpen={isModalOpen}
        onConfirm={confirmTranslation}
        onCancel={async () => {
          setIsModalOpen(false);
          isEnabledFetch.current = true;
          const newSearchParams = new URLSearchParams(searchParams.toString());
          activeLanguage === 'Original'
            ? newSearchParams.delete('lang')
            : newSearchParams.set('lang', activeLanguage);
          setSearchParams(newSearchParams);
          setDropdownLanguage(activeLanguage);
        }}
      />
    </>
  );
}

export default SingleTranscriptData;
