import { useContext } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { LangContext } from '@/context/LangProvider';
import { DeleteFilterModalProps } from '@/types';

export default function DeleteFilterModal({ isOpen, onConfirm, onCancel }: DeleteFilterModalProps) {
  const { lang } = useContext(LangContext);

  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <DialogContent className="max-w-md z-[101]">
        <DialogHeader>
          <DialogTitle className="mb-4">{lang.get('msg.confirmDeletion')}</DialogTitle>
          <DialogDescription className="text-dark-text/90">
            {lang.get('msg.areYouSureYouWantToDeleteFilter')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex justify-end mt-4">
          <Button variant="outline" onClick={onCancel}>
            {lang.get('msg.cancel')}
          </Button>
          <Button variant="destructive" onClick={onConfirm}>
            {lang.get('msg.delete')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
