import { useContext } from 'react';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@/components/ui/dialog';
import { LangContext } from '@/context/LangProvider';
import { ConfirmTranslationModalProps } from '@/types';

export default function ConfirmTranslationModal({
  isOpen,
  onConfirm,
  onCancel,
}: ConfirmTranslationModalProps) {
  const { lang } = useContext(LangContext);

  return (
    <Dialog open={isOpen} onOpenChange={onCancel}>
      <DialogContent className="max-w-md z-[1000]">
        <DialogHeader>
          <DialogTitle className="mb-4">{lang.get('msg.confirmTranslation')}</DialogTitle>
          <DialogDescription>
            {lang.get('msg.areYouSureYouWantToConfirmTranslations')}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex justify-end mt-6">
          <Button variant="outline" onClick={onCancel}>
            {lang.get('msg.cancel')}
          </Button>
          <Button onClick={onConfirm}>{lang.get('msg.submit')}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
