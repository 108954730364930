import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { IconLoader2 } from '@tabler/icons-react';
import { useQuery, InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { AxiosError } from 'axios';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import axiosInstance from '@/axios/axiosInstance';
import PaperAirplaneIcon from '@/components/icons/PaperAirplaneIcon';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList } from '@/components/ui/tabs';
import { toast } from '@/components/ui/use-toast';
import { EProjectStatus, EStatusCode, NavigationTabs } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import { cn } from '@/lib/utils';
import { useMutateReRunAnalyze } from '@/reactQuery/post';
import useErrorStore from '@/store/ErrorStore';
import useReportStore from '@/store/ReportStore';
import useResultTabStore from '@/store/ResultsTabStore';
import AISearch from '../Content/AISearch';
import ConversationsTranscripts from '../Content/ConversationsTranscripts';
import Overview from '../Content/Overview';
import Questions from '../Content/Questions';
import ProjectReport from './ProjectReport';
import FiltersSelect from '@/components/projects/single-project/filters/FiltersSelect';
import ProjectTabs from '@/components/project-tabs/ProjectTabs';

function ProjectResults() {
  const { lang } = useTranslation();
  const { activeTab, setActiveTab } = useResultTabStore();
  const { project_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const filter_id = searchParams.get('filter_id');
  const queryClient = useQueryClient();
  const { clearReportData } = useReportStore();
  const { currentTheme } = useTheme();
  const { setShowError } = useErrorStore();
  const [rerunCacheState, setRerunCacheState] = useState('');
  const [isLoadingBeforeApplyFilter, setIsLoadingBeforeApplyFilter] = useState(false);
  const [rerunLoadingState, setRerunLoadingState] = useState<'idle' | 'loading' | 'done'>('idle');
  const rerunChacheStateLocalStorageFilter = localStorage.getItem(
    `rerun_state_${project_id}_filter_id`,
  );
  const [enableFetch, setEnableFetch] = useState(true);
  const currentFilterId = localStorage.getItem(`rerun_filter_${filter_id}`);
  const isRerunLoading = rerunLoadingState === 'loading';
  const { mutateAsync: mutateAsyncReRun, isPending: isPendingReRun } = useMutateReRunAnalyze();
  const rerunFilterValues = Object.keys(localStorage)
    .filter((key: string) => key.startsWith('rerun_filter_'))
    .map((key) => localStorage.getItem(key))
    .filter((value): value is string => value !== null);
  const rerunFilter = localStorage.getItem(`rerun_filter_${filter_id}`);
  const addQueryParams = useCallback(
    (params: Record<string, string>) => {
      const newSearchParams = new URLSearchParams(searchParams);
      Object.entries(params).forEach(([key, value]) => {
        newSearchParams.set(key, value);
      });
      setSearchParams(newSearchParams);
    },
    [searchParams, setSearchParams],
  );

  const removeQueryParams = (keys: string[]) => {
    keys.forEach((key) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  };

  const { data: singleProjectData, error: projectDataError } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    enabled: !!project_id,
    select: (data) => data?.data,
    refetchOnWindowFocus: true,
  });

  const { data: newConversationsCount, refetch } = useQuery({
    queryFn: () =>
      axiosInstanceAnalyze.get(
        `/analyzer/run/${project_id}/state/${filter_id ? `?filter_id=${filter_id}` : ''}`,
      ),
    queryKey: ['new_conversations_count', { filter_id, project_id }],
    select: (data) => data?.data,
    enabled: rerunCacheState !== null && enableFetch,
    refetchInterval: 10000,
    refetchOnWindowFocus: true,
  });

  const onReRun = useCallback(
    async (f_id?: string | null): Promise<void> => {
      try {
        f_id && setIsLoadingBeforeApplyFilter(true);
        const res = await mutateAsyncReRun({ project_id, f_id });
        setRerunLoadingState(res?.data?.state);
        setRerunCacheState(res?.data?.state_id);
        localStorage.setItem(`rerun_state_${project_id}`, res?.data?.state_id);
        if (f_id) {
          localStorage.setItem(`rerun_state_${project_id}_filter_id`, f_id);
        }
      } catch {
        toast({ description: lang.get('msg.errorPleaseTryAgain'), variant: 'destructive' });
      }
    },
    [mutateAsyncReRun, project_id, lang],
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleTabChange = useCallback(
    (value: string) => {
      setActiveTab(value);
      scrollToTop();
    },
    [scrollToTop],
  );

  const analysisLanguage = singleProjectData?.analysis_language;

  const showFilters = singleProjectData?.status === EProjectStatus.COMPLETED;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    clearReportData();
    setActiveTab(NavigationTabs.OVERVIEW);
  }, [clearReportData, setActiveTab]);

  useEffect(() => {
    setRerunLoadingState(newConversationsCount?.state);
    if (newConversationsCount?.state) {
      setIsLoadingBeforeApplyFilter(false);
    }
  }, [newConversationsCount]);

  useEffect(() => {
    if (rerunLoadingState === 'done') {
      const handleReRunComplete = async () => {
        try {
          if (!filter_id || (filter_id && currentFilterId)) {
            await queryClient.invalidateQueries(['overall_data'] as InvalidateQueryFilters);
            await queryClient.invalidateQueries(['question_insights'] as InvalidateQueryFilters);
            await queryClient.invalidateQueries(['question_transcripts'] as InvalidateQueryFilters);
            await queryClient.invalidateQueries(['category_data'] as InvalidateQueryFilters);
            await queryClient.invalidateQueries([
              'new_conversations_count',
            ] as InvalidateQueryFilters);
          }

          Object.keys(localStorage).forEach((key) => {
            if (key.startsWith('rerun_filter_') && key.includes(project_id!)) {
              localStorage.removeItem(key);
            }
          });
          localStorage.removeItem(`rerun_state_${project_id}`);
          localStorage.removeItem(`rerun_state_${project_id}_filter_id`);
          localStorage.removeItem(`rerun_filter_${filter_id}`);

          if (singleProjectData?.status !== EProjectStatus.COMPLETED) {
            toast({ description: 'Re-run is done!' });
          } else {
            if (rerunChacheStateLocalStorageFilter) {
              addQueryParams({ filter_id: rerunChacheStateLocalStorageFilter });
            }
            removeQueryParams(['edit']);
            if (!filter_id) {
              toast({
                description: 'The analysis has been successfully run!',
              });
              return;
            }
            if (currentFilterId) {
              toast({
                description: 'Filter is successfully created and applied to your project',
              });
            }
          }
        } catch (error) {
          console.error('Error during re-run completion handling:', error);
        }
      };

      handleReRunComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    rerunLoadingState,
    project_id,
    singleProjectData?.status,
    rerunChacheStateLocalStorageFilter,
  ]);

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  const isRerunDisabled =
    !isPendingReRun && newConversationsCount?.numberOfUnRunConversations === 0;

  const reRunButtonText = () => {
    if (isRerunLoading) return 'Re-running';

    if (isRerunDisabled) return 'No new conversations';

    return 'Re-run';
  };

  return (
    <main
      style={{ backgroundColor: currentTheme?.background, color: currentTheme?.['primary-text'] }}
      className="flex flex-col w-full h-full"
    >
      {/* tabs */}
      <Tabs
        defaultValue="overview_tab"
        className="w-full"
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <div
          style={{
            backgroundColor: currentTheme?.background,
            color: currentTheme?.['primary-text'],
          }}
          className="fixed flex items-center z-[99] justify-between w-full py-2 -mt-[9px] border-b"
        >
          <TabsList
            style={{
              backgroundColor: currentTheme?.background,
              color: currentTheme?.['primary-text'],
            }}
            className="relative flex items-center justify-start w-full h-fit max1200:flex-wrap max1200:gap-5"
          >
            <ProjectTabs currentTheme={currentTheme} lang={lang} activeTab={activeTab} />
          </TabsList>

          {singleProjectData?.status !== EProjectStatus.COMPLETED && (
            <Button
              variant="default"
              disabled={isRerunDisabled || isRerunLoading}
              className="relative flex epilogue-font items-center gap-2 mr-[4%] font-bold px-7"
              onClick={() => onReRun()}
            >
              {isPendingReRun || isRerunLoading ? (
                <IconLoader2 className="animate-spin" size={20} />
              ) : (
                <PaperAirplaneIcon className="size-4" />
              )}
              {reRunButtonText()}
              {rerunLoadingState !== 'loading' &&
                newConversationsCount?.numberOfUnRunConversations > 0 && (
                  <span className="absolute flex items-center justify-center h-5 px-1.5 text-xs text-white bg-red-600 rounded-full w-fit -top-1 -right-1.5">
                    {newConversationsCount?.numberOfUnRunConversations}
                  </span>
                )}
            </Button>
          )}

          {singleProjectData?.status === EProjectStatus.COMPLETED &&
            isRerunLoading &&
            filter_id && (
              <Button
                variant="default"
                disabled
                className="flex items-center gap-2 mr-[4%] font-bold px-7"
              >
                <IconLoader2 className="animate-spin" size={20} />
                <p className="epilogue-font">{lang.get('msg.applyingFilter')}</p>
              </Button>
            )}
        </div>

        {/* filters */}
        {showFilters && (
          <div
            style={{ borderColor: currentTheme?.['primary-text'] }}
            className="flex items-center justify-start w-full h-16 gap-3 mx-auto mt-12 border-b md900:mt-24 md600:mt-40 border-light-gray/20"
          >
            {/* select filter */}
            <FiltersSelect
              onReRun={onReRun}
              rerunFilterValues={rerunFilterValues}
              onFilterDeleteEnableRefetch={setEnableFetch}
              refetch={refetch}
            />
          </div>
        )}

        <TabsContent
          value={NavigationTabs.OVERVIEW}
          className={cn(
            showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44',
            'pb-10 !ring-transparent !ring-0',
          )}
        >
          {activeTab === NavigationTabs.OVERVIEW && (
            <Overview
              rerunFilter={rerunFilter}
              isPendingReRun={isPendingReRun}
              analysisLanguage={analysisLanguage}
              singleProjectData={singleProjectData}
              newConversationsCount={newConversationsCount?.numberOfUnRunConversations}
              newConversationsState={newConversationsCount?.state}
              isRerunLoading={isRerunLoading}
              onReRun={onReRun}
              isLoadingBeforeApplyFilter={isLoadingBeforeApplyFilter}
            />
          )}
        </TabsContent>
        <TabsContent
          value={NavigationTabs.TRANSCRIPTS}
          className={cn(
            showFilters ? 'pt-1' : 'pt-14 max1050:pt-28 min505:pt-72 md700:pt-44',
            'pb-10 !ring-transparent !ring-0',
          )}
        >
          {activeTab === NavigationTabs.TRANSCRIPTS && (
            <ConversationsTranscripts analysisLanguage={analysisLanguage || 'English'} />
          )}
        </TabsContent>
        <TabsContent
          value={NavigationTabs.QUESTIONS}
          className={cn(
            showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44',
            'pb-10 !ring-transparent !ring-0',
          )}
        >
          {activeTab === NavigationTabs.QUESTIONS && (
            <Questions
              analysisLanguage={analysisLanguage}
              isPendingReRun={isPendingReRun}
              singleProjectData={singleProjectData}
              newConversationsCount={newConversationsCount?.numberOfUnRunConversations}
              newConversationsState={newConversationsCount?.state}
              isRerunLoading={isRerunLoading}
              onReRun={onReRun}
              enableFetch={enableFetch}
              isLoadingBeforeApplyFilter={isLoadingBeforeApplyFilter}
            />
          )}
        </TabsContent>
        <TabsContent
          value={NavigationTabs.AISEARCH}
          className={cn(
            showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44',
            'pb-10 !ring-transparent !ring-0',
          )}
        >
          {activeTab === NavigationTabs.AISEARCH && <AISearch />}
        </TabsContent>
        <TabsContent
          value={NavigationTabs.REPORTS}
          className={cn(
            showFilters ? 'pt-5' : 'pt-20 max1050:pt-28 min505:pt-72 md700:pt-44',
            'pb-10 !ring-transparent !ring-0',
          )}
        >
          {activeTab === NavigationTabs.REPORTS && (
            <ProjectReport isPendingReRun={isPendingReRun} analysisLanguage={analysisLanguage} />
          )}
        </TabsContent>
      </Tabs>
    </main>
  );
}

export default ProjectResults;
