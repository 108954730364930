import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { cn } from 'src/lib/utils';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import axiosInstance from '@/axios/axiosInstance';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Input } from '@/components/ui/input';
import { Textarea } from '@/components/ui/textarea';
import { ECompanyThemeName } from '@/enums';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import languagesList from '@/lang/languages/languagesList';
import InstructionsSkelet from './components/InstructionsSkelet';
import toneOfVoiceList from './components/toneOfVoiceList';
import useCreateProjectStore from '@/store/CreateProjectStore';
import getTextColor from '@/utils/getTextColorContrast';
import { Checkbox } from '@/components/ui/checkbox';
import ToolTip from '@/components/tooltip/ToolTip';
import { InstructionsProps } from '@/types';

const typeOfInterviewList = ['text', 'audio', 'video'];

function Instructions({
  isProjectPublished,
  isProjectCompleted,
  singleProjectData,
  register,
  control,
  setValue,
  errors,
  isProjectDataLoading,
}: InstructionsProps) {
  const { lang } = useTranslation();
  const { organization_id, workspace_id } = useParams();
  const [interviewGoalCharacters, setInterviewGoalCharacters] = useState(
    singleProjectData?.interview_goal || '',
  );
  const [interviewAudienceCharacters, setInterviewAudienceCharacters] = useState(
    singleProjectData?.interview_audience || '',
  );
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { themeName, currentTheme } = useTheme();
  const { setIsFormChanged } = useCreateProjectStore();

  const { data: allBrandingsList, isLoading: isAllBrandingsListLoading } = useQuery({
    queryFn: () => axiosInstance.get('/themes/organization/all'),
    queryKey: ['all_brandings_data', { organization_id }],
    enabled: !!organization_id,
    select: (data) =>
      data?.data?.map((branding: { _id: string; name: string }) => ({
        label: branding.name,
        value: branding._id,
      })),
  });

  const isReadOnlyMode = isUserViewer || isProjectCompleted;
  const isFullyDisabled = isReadOnlyMode || isProjectPublished;
  const isDataLoaded = !isProjectDataLoading && !isAllBrandingsListLoading;
  const interviewGoalMaxLength = 700;

  return (
    <div className="w-full h-full pl-1">
      {isDataLoaded ? (
        <div className="flex flex-col w-full gap-5 pb-10 pr-1">
          {/* -- type of interview -- */}
          <div className="flex flex-col w-full gap-2">
            <label className="relative flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <p className="flex items-center gap-1 text-[15px] font-medium">
                  {lang.get('msg.responseTypes')}
                  <span className="text-red-600">*</span>
                </p>
                <ToolTip
                  text={lang.get('msg.typeOfInterviewInfo')}
                  className="max-w-lg border-[3px] border-primary text-sm z-100"
                />
              </div>
            </label>
            <div className="flex items-center gap-10 w-fit">
              {typeOfInterviewList.map((type) => (
                <Controller
                  key={type}
                  name="type_of_interview"
                  control={control}
                  render={({ field }) => (
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={type}
                        checked={field.value?.includes(type)}
                        disabled={isReadOnlyMode}
                        onCheckedChange={(checked) => {
                          const updatedValue = checked
                            ? [...field.value, type]
                            : field.value.filter((val: string) => val !== type);

                          if (!checked && field.value.length === 1) return;

                          setIsFormChanged(true);
                          field.onChange(updatedValue);
                        }}
                      />
                      <label
                        htmlFor={type}
                        className="text-sm font-medium leading-none cursor-pointer select-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </label>
                    </div>
                  )}
                />
              ))}
            </div>
          </div>
          {/* -- interviewer name -- */}
          <RequiredLabel title="interviewerName" tooltip={lang.get('msg.interviewerNameInfo')}>
            <Input
              disabled={isFullyDisabled}
              {...register('interviewer_name')}
              type="text"
              placeholder={lang.get('msg.interviewerNamePlaceholder')}
              onChange={(e) => {
                setIsFormChanged(true);
                setValue('interviewer_name', e.target.value, { shouldDirty: true });
              }}
            />
          </RequiredLabel>

          {/* -- company name -- */}
          <RequiredLabel title="companyName" tooltip={lang.get('msg.companyNameInfo')}>
            <Input
              disabled={isFullyDisabled}
              {...register('company_name')}
              type="text"
              placeholder={lang.get('msg.companyNamePlaceholder')}
              onChange={(e) => {
                setIsFormChanged(true);
                setValue('company_name', e.target.value, { shouldDirty: true });
              }}
            />
          </RequiredLabel>

          {/* -- interview goal -- */}
          <RequiredLabel
            title="myResearchObjective"
            tooltip={lang.get('msg.myResearchObjectiveInfo')}
          >
            <Textarea
              disabled={isFullyDisabled}
              {...register('interview_goal')}
              maxLength={interviewGoalMaxLength}
              className="h-28 rounded-xl"
              placeholder={lang.get('msg.myResearchObjectivePlaceholder')}
              onChange={(e) => {
                setInterviewGoalCharacters(e.target.value);
                setIsFormChanged(true);
                setValue('interview_goal', e.target.value, { shouldDirty: true });
              }}
            />
            <p className="text-xs text-right text-light-dark">
              {interviewGoalMaxLength - interviewGoalCharacters.length}{' '}
              {lang.get('msg.charactersLeft')}
            </p>
          </RequiredLabel>

          {/* -- interview audience -- */}
          <RequiredLabel
            title="interviewAudience"
            tooltip={
              themeName === ECompanyThemeName.EMPATHY
                ? lang.get('msg.interviewAudienceInfo_empathy')
                : lang.get('msg.interviewAudienceInfo')
            }
          >
            <Textarea
              disabled={isFullyDisabled}
              {...register('interview_audience')}
              onChange={(e) => {
                setInterviewAudienceCharacters(e.target.value);
                setIsFormChanged(true);
                setValue('interview_audience', e.target.value, { shouldDirty: true });
              }}
              className="h-28 rounded-xl"
              maxLength={100}
              placeholder={lang.get('msg.interviewAudiencePlaceholder')}
            />
            <p className="text-xs text-right text-light-dark">
              {100 - interviewAudienceCharacters.length} {lang.get('msg.charactersLeft')}
            </p>
          </RequiredLabel>

          {/* language */}
          <RequiredLabel title="primaryLanguage" tooltip={lang.get('msg.primaryLanguageInfo')}>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select
                  value={languagesList.language.find((option) => option.label === field?.value)}
                  onChange={(value) => {
                    field.onChange(value?.label);
                    setIsFormChanged(true);
                  }}
                  options={languagesList.language}
                  isDisabled={isFullyDisabled}
                  placeholder={lang.get('msg.selectInterviewLanguage')}
                  className={cn('peer rounded-md transition-all text-gray-700 text-sm')}
                  styles={{
                    placeholder: (defaultStyles) => ({
                      ...defaultStyles,
                      color: currentTheme?.['input-placeholder-color'],
                    }),
                    control: (styles, { isDisabled }) => ({
                      ...styles,
                      backgroundColor: isDisabled
                        ? currentTheme?.['input-invalid-color']
                        : '#FFFFFF',
                      borderColor: currentTheme?.['input-invalid-color'],
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                      color: state.isFocused
                        ? getTextColor(currentTheme?.primary as string)
                        : '#000000',
                      ':active': {
                        backgroundColor: currentTheme?.primary,
                      },
                    }),
                  }}
                />
              )}
            />
          </RequiredLabel>

          {/* allow language changing */}
          <RequiredLabel
            title="allowParticipantsToChooseTheLanguage"
            tooltip={lang.get('msg.allowToChooseLanguageInfo')}
          >
            <Controller
              name="allow_language_selection"
              control={control}
              render={({ field }) => (
                <div className="flex flex-col gap-1.5 mb-2">
                  <div className="flex items-center gap-1.5">
                    <input
                      type="radio"
                      id="allowLanguageYes"
                      value="true"
                      checked={field.value === true}
                      disabled={isFullyDisabled}
                      onChange={() => {
                        setIsFormChanged(true);
                        setValue('allow_language_selection', true, { shouldDirty: true });
                      }}
                    />
                    <label className="text-sm" htmlFor="allowLanguageYes">
                      {lang.get('msg.yes')}
                    </label>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <input
                      type="radio"
                      id="allowLanguageNo"
                      value="false"
                      checked={field.value === false}
                      disabled={isFullyDisabled}
                      onChange={() => {
                        setValue('allow_language_selection', false, { shouldDirty: true });
                        setIsFormChanged(true);
                      }}
                    />
                    <label className="text-sm" htmlFor="allowLanguageNo">
                      {lang.get('msg.no')}
                    </label>
                  </div>
                </div>
              )}
            />
          </RequiredLabel>

          {/* analysis language */}
          <RequiredLabel title="analysisLanguage" tooltip={lang.get('msg.analysisLanguageInfo')}>
            <Controller
              name="analysis_language"
              control={control}
              render={({ field }) => (
                <Select
                  value={languagesList.language.find((option) => option.label === field?.value)}
                  onChange={(value) => {
                    field.onChange(value?.label);
                    setIsFormChanged(true);
                  }}
                  className={cn('peer rounded-md transition-all text-gray-700 text-sm')}
                  options={languagesList.language}
                  isDisabled={isReadOnlyMode}
                  placeholder={lang.get('msg.selectAnalysisLanguage')}
                  styles={{
                    control: (styles, { isDisabled }) => ({
                      ...styles,
                      backgroundColor: isDisabled
                        ? currentTheme?.['input-invalid-color']
                        : '#FFFFFF',
                      borderColor: currentTheme?.['input-invalid-color'],
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                      color: state.isFocused
                        ? getTextColor(currentTheme?.primary as string)
                        : '#000000',
                      ':active': {
                        backgroundColor: currentTheme?.primary,
                      },
                    }),
                  }}
                />
              )}
            />
          </RequiredLabel>

          {/* privacy policy */}
          <RequiredLabel title="privacyPolicy" tooltip={lang.get('msg.privacyPolicyInfo')}>
            <div className="relative w-full">
              <Input
                disabled={isReadOnlyMode}
                {...register('privacy_policy')}
                type="text"
                placeholder={lang.get('msg.insertYourPrivacyPolicyHere')}
                hasError={!!errors?.privacy_policy}
                onChange={(e) => {
                  setIsFormChanged(true);
                  setValue('privacy_policy', e.target.value);
                  setValue('privacy_policy', e.target.value, { shouldDirty: true });
                }}
              />
              {!!errors?.privacy_policy && (
                <p className="left-0 text-xs font-medium text-red-600 -bottom-5">
                  {errors?.privacy_policy?.message}
                </p>
              )}
            </div>
          </RequiredLabel>

          {/* -- tone -- */}
          <RequiredLabel title="toneOfVoice" tooltip={lang.get('msg.toneOfVoiceInfo')}>
            <Controller
              name="tone_of_voice"
              control={control}
              render={({ field }) => (
                <Select
                  value={toneOfVoiceList.find((option) => option.value === field?.value)}
                  onChange={(value) => {
                    field.onChange(value?.value);
                    setIsFormChanged(true);
                  }}
                  options={toneOfVoiceList.map((option) => ({
                    ...option,
                    label: lang.get(`msg.${option.label}`),
                  }))}
                  isDisabled={isFullyDisabled}
                  placeholder={lang.get('msg.selectToneOfVoice')}
                  menuPlacement="top"
                  className={cn('peer rounded-md transition-all text-gray-700 text-sm')}
                  styles={{
                    placeholder: (defaultStyles) => ({
                      ...defaultStyles,
                      color: currentTheme?.['input-placeholder-color'],
                    }),
                    control: (styles, { isDisabled }) => ({
                      ...styles,
                      backgroundColor: isDisabled
                        ? currentTheme?.['input-invalid-color']
                        : '#FFFFFF',
                      borderColor: currentTheme?.['input-invalid-color'],
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                      color: state.isFocused
                        ? getTextColor(currentTheme?.primary as string)
                        : '#000000',
                      ':active': {
                        backgroundColor: currentTheme?.primary,
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      textTransform: 'capitalize',
                    }),
                  }}
                />
              )}
            />
          </RequiredLabel>

          {/* branding */}
          <RequiredLabel
            notRequired
            title="projectBranding"
            tooltip={lang.get('msg.projectBranding')}
          >
            <Controller
              name="theme_id"
              control={control}
              render={({ field }) => (
                <Select
                  value={allBrandingsList?.find(
                    (option: { value: string }) => option.value === field?.value,
                  )}
                  className={cn('peer rounded-md transition-all text-gray-700 text-sm')}
                  onChange={(value) => {
                    field.onChange(value?.value);
                    setIsFormChanged(true);
                    setValue('theme_id', value?.value, { shouldDirty: true });
                  }}
                  options={allBrandingsList}
                  isDisabled={isReadOnlyMode}
                  placeholder={lang.get('msg.projectBranding')}
                  menuPlacement="top"
                  styles={{
                    control: (styles) => ({
                      ...styles,
                      borderColor: currentTheme?.['input-invalid-color'],
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                      color: state.isFocused
                        ? getTextColor(currentTheme?.primary as string)
                        : '#000000',
                      ':active': {
                        backgroundColor: currentTheme?.primary,
                      },
                    }),
                  }}
                />
              )}
            />
          </RequiredLabel>
        </div>
      ) : (
        <InstructionsSkelet />
      )}
    </div>
  );
}

export default Instructions;
