import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import Snippets from './components/Snippets';
import { ContextProps } from '@/types';

function Context({
  workspaceID,
  isProjectPublished,
  isProjectCompleted,
  control,
  watch,
  register,
  setValue,
}: ContextProps) {
  const { isUserViewer } = useWorkspaceRoles(workspaceID);

  return (
    <div className="w-full h-full pl-1">
      <div className="flex flex-col w-full gap-5 pb-10">
        {/* -- snippets -- */}
        <Snippets
          control={control}
          watch={watch}
          register={register}
          isUserViewer={isUserViewer}
          isProjectPublished={isProjectPublished}
          isProjectCompleted={isProjectCompleted}
          setValue={setValue}
        />
      </div>
    </div>
  );
}

export default Context;
