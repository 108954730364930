import { useEffect } from 'react';
import { useBlocker, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { EStatusCode } from '@/enums';
import axiosInstance from '@/axios/axiosInstance';
import ProjectShareLink from '@/components/projects/single-project/share/ProjectShareLink';
import ProjectSharePanel from '@/components/projects/single-project/share/ProjectSharePanel';
import useErrorStore from '@/store/ErrorStore';
import FullPageLoader from '@/components/atoms/Loader/FullPageLoader';
import ProjectShareTestLink from '@/components/projects/single-project/share/ProjectShareTestLink';
import UnsavedChangesModal from '@/components/Modals/UnsavedChangesModal';
import useCreateProjectStore from '@/store/CreateProjectStore';

function ProjectShare() {
  const { project_id } = useParams();
  const { setShowError } = useErrorStore();
  const { isFormChanged } = useCreateProjectStore();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isFormChanged && currentLocation.pathname !== nextLocation.pathname,
  );

  const {
    data: singleProjectData,
    error: projectDataError,
    isLoading,
  } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  useEffect(() => {
    if ((projectDataError as AxiosError)?.status === EStatusCode.BAD_REQUEST) {
      setShowError(true);
    }
  }, [projectDataError, setShowError]);

  if (blocker.state === 'blocked') return <UnsavedChangesModal blocker={blocker} />;

  if (isLoading) return <FullPageLoader />;

  return (
    <main className="flex flex-col w-full h-full mx-auto pt-5 gap-10">
      <ProjectShareTestLink singleProjectData={singleProjectData} />
      <ProjectShareLink singleProjectData={singleProjectData} />
      <ProjectSharePanel singleProjectData={singleProjectData} />
    </main>
  );
}

export default ProjectShare;
