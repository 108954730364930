import { TablerIconsProps, IconHash, IconCircleDot, IconCheckbox } from '@tabler/icons-react';
import { ScreeningQuestionType } from '@/enums';

export const screeningQuestionTypeIcon: Record<ScreeningQuestionType, TablerIconsProps> = {
  [ScreeningQuestionType.MULTI_SELECT]: <IconCheckbox size={18} />,
  [ScreeningQuestionType.SINGLE_SELECT]: <IconCircleDot size={18} />,
  [ScreeningQuestionType.NUMERIC]: <IconHash size={18} />,
};

export const screeningQuestionTypeOptions = [
  {
    id: 1,
    icon: <IconCheckbox size={18} />,
    title: ScreeningQuestionType.MULTI_SELECT,
  },
  {
    id: 2,
    icon: <IconCircleDot size={18} />,
    title: ScreeningQuestionType.SINGLE_SELECT,
  },
  {
    id: 3,
    icon: <IconHash size={18} />,
    title: ScreeningQuestionType.NUMERIC,
  },
];
