import { IconDownload } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import DOMPurify from 'isomorphic-dompurify';
import dayjs from 'dayjs';
import { cn } from '@/lib/utils';
import AudioMessage from './audio/AudioMessage';
import VideoMessage from './video/VideoMessage';
import { EMessageType } from '@/enums';
import useResultTabStore from '@/store/ResultsTabStore';

function ConversationMessage({ role, data, text, created_at }: TranscriptsMessages) {
  const { currentTheme } = useTheme();
  const sanitizedText = DOMPurify.sanitize(text || '');
  const { activeTab } = useResultTabStore();
  return (
    <div className="flex flex-col gap-1">
      <div
        style={{
          backgroundColor:
            (role === 'assistant' && '#FFFFFF') || (role === 'user' && currentTheme?.primary) || '',
        }}
        className={cn(
          'max-w-xl',
          role === 'assistant' &&
            'self-start border rounded-lg mr-[52px] min450:mr-0 px-4 py-2 text-[15px] shadow-sm text-dark-text border-dark-text-10',
          role === 'user' &&
            'border self-end rounded-lg px-4 py-2 ml-[52px] min450:ml-0 text-[15px] shadow-sm text-white border-dark-text/10"',
        )}
      >
        <div
          className={cn(
            'flex gap-4 flex-col',
            role === 'assistant' && 'justify-start',
            role === 'user' && 'justify-end',
          )}
        >
          {/*  eslint-disable-next-line react/no-danger */}
          <p
            className="[&_a]:underline [&_a]:text-blue-400"
            dangerouslySetInnerHTML={{ __html: sanitizedText as string }}
          />

          {data && data[0]?.type === EMessageType.image && (
            <div className="relative">
              <a
                style={{ color: currentTheme?.primary }}
                className={cn(
                  role === 'user' ? '-left-6' : '-right-6',
                  'absolute hover:shadow-xl p-1 -bottom-4 bg-white border rounded-full shadow',
                )}
                href={data[0]?.url}
                download="tellet-image"
              >
                <IconDownload size={17} />
              </a>
              <img
                src={data[0]?.url}
                alt=""
                className={cn(
                  'max-w-[20rem] my-2 rounded-md max-h-fit',
                  activeTab === 'transcripts_tab' ? 'max-w-[20rem]' : 'max-w-[16rem]',
                )}
              />
            </div>
          )}

          {data && data[0]?.type === EMessageType.audio && (
            <div className="relative">
              <a
                style={{ color: currentTheme?.primary }}
                className={cn(
                  role === 'user' ? '-left-6' : '-right-6',
                  'absolute hover:shadow-xl p-1 -bottom-4 bg-white border rounded-full shadow',
                )}
                href={data[0]?.url}
                download="tellet-image"
              >
                <IconDownload size={16} />
              </a>
              <AudioMessage
                isUser={!!(role === 'user')}
                url={data[0]?.url}
                transcript={data[0]?.transcription_text}
              />
            </div>
          )}

          {data && data[0]?.type === EMessageType.video && (
            <div className="relative">
              <a
                style={{ color: currentTheme?.primary }}
                className={cn(
                  role === 'user' ? '-left-6' : '-right-6',
                  'absolute hover:shadow-xl p-1 -bottom-4 bg-white border rounded-full shadow',
                )}
                href={data[0]?.url}
                download="tellet-image"
              >
                <IconDownload size={17} />
              </a>
              <VideoMessage url={data?.[0]?.url} transcript={data?.[0]?.transcription_text} />
            </div>
          )}
        </div>
      </div>
      <p
        className={cn(
          role === 'user' ? 'justify-end pr-1' : 'justify-start pl-1',
          'flex w-full text-black font-medium text-[10px]',
        )}
      >
        {dayjs(created_at).format('hh:mm:ss A')}
      </p>
    </div>
  );
}

export default ConversationMessage;
