import { create } from 'zustand';

export interface RequiredField {
  key: string;
  label: string;
  isEmpty: boolean;
}

type State = {
  requiredFields: RequiredField[];
  emptyFieldsLabels: string[];
};

type Action = {
  setRequiredFields: (fields: RequiredField[]) => void;
};

const initialRequiredFields: RequiredField[] = [
  { key: 'interviewer_name', label: 'interviewerName', isEmpty: true },
  { key: 'company_name', label: 'companyName', isEmpty: true },
  { key: 'interview_goal', label: 'myResearchObjective', isEmpty: true },
  { key: 'interview_audience', label: 'interviewAudience', isEmpty: true },
  { key: 'language', label: 'primaryLanguage', isEmpty: true },
  {
    key: 'allow_language_selection',
    label: 'allowParticipantsToChooseTheLanguage',
    isEmpty: true,
  },
  { key: 'analysis_language', label: 'analysisLanguage', isEmpty: true },
  { key: 'privacy_policy', label: 'privacyPolicy', isEmpty: true },
  { key: 'tone_of_voice', label: 'toneOfVoice', isEmpty: true },
  { key: 'opening_message', label: 'openingMessage', isEmpty: true },
  { key: 'interview_questions', label: 'interviewQuestions', isEmpty: true },
  { key: 'respondents_max', label: 'maximumNumberOfRespondets', isEmpty: true },
];

const useProjectRequiredFieldsStore = create<State & Action>((set) => ({
  requiredFields: initialRequiredFields,
  emptyFieldsLabels: initialRequiredFields.map((field) => field.label),

  setRequiredFields: (fields) => {
    set({ requiredFields: fields });
    const emptyFields = fields.filter((field) => field.isEmpty).map((field) => field.label);
    set({ emptyFieldsLabels: emptyFields });
  },
}));

export default useProjectRequiredFieldsStore;
