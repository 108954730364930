import useTranslation from '@/hooks/useTranslation';
import ToolTip from '../tooltip/ToolTip';
import { RequiredLabelProps } from '@/types';

function RequiredLabel({
  children,
  title,
  tooltip,
  icon,
  notRequired = false,
  isFullWidth = false,
}: RequiredLabelProps) {
  const { lang } = useTranslation();

  return (
    <label className={`relative flex flex-col gap-2 w-full ${isFullWidth ? 'w-full' : 'w-auto'}`}>
      <div className="flex items-center justify-between">
        <p className="flex items-center gap-1 text-[15px] font-medium">
          {icon}
          {lang.get(`msg.${title}`)}
          {!notRequired && <span className="text-red-600">*</span>}
        </p>
        {tooltip && (
          <ToolTip text={tooltip} className="max-w-lg border-[3px] border-primary text-sm z-100" />
        )}
      </div>
      {children}
    </label>
  );
}

export default RequiredLabel;
