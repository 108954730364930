import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { LangContext } from '@/context/LangProvider';
import { EProjectStatus } from '@/enums';
import { Input } from '@/components/ui/input';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import { ProjectShareMaxRespondentsProps } from '@/types';
import RequiredLabel from '@/components/labels/RequiredLabel';

function ProjectShareMaxRespondets({
  singleProjectData,
  register,
  errors,
  setValue,
  setIsFormChanged,
}: ProjectShareMaxRespondentsProps) {
  const { lang } = useContext(LangContext);
  const { workspace_id } = useParams();
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const { isUserViewer } = useWorkspaceRoles(workspace_id);

  return (
    <RequiredLabel
      title="maximumNumberOfParticipants"
      tooltip={lang.get('msg.maximumNumberOfParticipantsTooltip')}
    >
      <Input
        type="number"
        disabled={isUserViewer || isProjectCompleted}
        {...register('respondents_max')}
        placeholder="Add maximum number of participants"
        hasError={!!errors?.respondents_max?.message}
        onChange={(e) => {
          setIsFormChanged(true);
          setValue('respondents_max', e.target.value, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }}
      />

      <p
        className={cn(
          errors?.respondents_max?.message ? 'flex' : 'hidden',
          'text-red-600 text-xs font-semibold',
        )}
      >
        {errors?.respondents_max?.message as string}
      </p>
    </RequiredLabel>
  );
}

export default ProjectShareMaxRespondets;
