import { Label } from '@radix-ui/react-dropdown-menu';
import { Check, ChevronDown } from 'lucide-react';
import { IconLoader2 } from '@tabler/icons-react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import useTranslation from '@/hooks/useTranslation';
import { Button } from '@/components/ui/button';
import { TranscriptsTranslationDropdownProps } from '@/types';

function TranscriptsTranslationDropdown({
  isLoading,
  activeLanguage,
  handleLanguageSelection,
  translatedLanguages,
  unTranslatedLanguages,
}: TranscriptsTranslationDropdownProps) {
  const { lang } = useTranslation();

  return (
    <div className="flex flex-col gap-2 relative">
      <Label className="absolute -top-5 left-0 text-xs font-bold">{lang.get('msg.language')}</Label>
      <DropdownMenu onOpenChange={(open) => !isLoading && open}>
        <DropdownMenuTrigger asChild>
          <Button
            disabled={isLoading}
            variant="ghost"
            className="flex items-center border border-gray-300 min-w-40 justify-between"
          >
            {isLoading && <IconLoader2 className="animate-spin mr-2" size={20} />}
            {activeLanguage}
            <ChevronDown className="w-4 h-4 ml-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-40 max-h-60 overflow-y-auto">
          {/* Translated Languages */}
          <Label className="font-semibold text-xs ml-3 mt-2 mb-1">
            {lang.get('msg.translations')}
          </Label>
          {translatedLanguages?.map((language) => (
            <DropdownMenuCheckboxItem
              key={language}
              className={`capitalize cursor-pointer ${
                language === activeLanguage &&
                'bg-gray-200 cursor-auto text-dark-text pointer-events-none'
              }`}
              onClick={() => handleLanguageSelection(language)}
            >
              {language}
              {language === activeLanguage && <Check className="w-4 h-4 ml-auto" />}
            </DropdownMenuCheckboxItem>
          ))}

          <hr className="solid my-2"></hr>

          {/* Untranslated Languages */}
          <Label className="font-semibold text-xs ml-3 mt-2 mb-1">
            {lang.get('msg.addTranslations')}
          </Label>
          {unTranslatedLanguages?.map((language) => (
            <DropdownMenuCheckboxItem
              key={language}
              className="capitalize cursor-pointer"
              onClick={() => handleLanguageSelection(language)}
            >
              {language}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

export default TranscriptsTranslationDropdown;
