import { ColumnDef } from '@tanstack/react-table';
import { useSearchParams } from 'react-router-dom';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { IconKeyboard, IconVideo, IconVolume } from '@tabler/icons-react';
import UserFilledIcon from '@/components/icons/UserFilledIcon';
import TooltipWrapper from '@/components/tooltip/TooltipWrapper';
import replaceUnderscoreWithSpace from '@/utils/transformUnderscoreToSpace';
import useTranslation from '@/hooks/useTranslation';
import { AllTranscriptsProps, TableSortingMethods } from '@/types';
import TranscriptsColumnsHeaderButton from './TranscriptsColumnsHeaderButton';
import TranscriptsColumnsCell from './TranscriptsColumnsCell';

const TranscriptsColumns = (metadataKeysArray: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { currentTheme } = useTheme();
  const { lang } = useTranslation();

  const addQueryParams = (params: Record<string, string>) => {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.set(key, value);
    });
    setSearchParams(searchParams);
  };

  const getColor = (row) => {
    if (row?.original?.inRun === true && !row?.original?.language) return currentTheme?.primary;
    if (row?.original?.language) return '#808080';
    return '#ff8c00';
  };

  const toggleSortHandler = ({ toggleSorting, getIsSorted }: TableSortingMethods) =>
    toggleSorting(getIsSorted() === 'asc');

  const columns: ColumnDef<AllTranscriptsProps>[] = [
    {
      accessorKey: 'participants',
      header: () => (
        <TranscriptsColumnsHeaderButton text={lang.get('msg.participant')} onClick={() => {}} />
      ),
      cell: ({ row }) => {
        return (
          <button
            type="button"
            className="flex items-center h-full gap-3 group whitespace-nowrap"
            onClick={() =>
              addQueryParams({
                selected_transcript: row?.original?.conversation_id ?? row?.original?._id,
              })
            }
          >
            <div className="flex items-center gap-3 font-semibold transition-all ease-in-out group-hover:underline">
              <div style={{ color: getColor(row) }}>
                <UserFilledIcon className="size-7" />
              </div>
              <p>
                {lang.get('msg.participant')} {row?.original?.respondent_index}
              </p>
              <div className="flex items-center ml-2 gap-1.5 text-black/80 whitespace-nowrap">
                {row?.original?.messages?.some(
                  (item) => item?.text !== '' && item?.role === 'user',
                ) && <IconKeyboard size={24} />}
                {row?.original?.messages.some(
                  (message) =>
                    message?.data?.some((dataItem: File) => dataItem.type === 'audio') &&
                    message.role === 'user',
                ) && <IconVolume size={24} />}
                {row?.original?.messages.some(
                  (message) =>
                    message?.data?.some((dataItem: File) => dataItem.type === 'video') &&
                    message.role === 'user',
                ) && <IconVideo size={24} />}
              </div>
            </div>
          </button>
        );
      },
    },
    {
      accessorFn: (row) => row.conversation_id || row._id,
      id: 'id',
      header: ({ column }) => (
        <TranscriptsColumnsHeaderButton
          text={lang.get('msg.conversationID')}
          onClick={() => toggleSortHandler(column)}
        />
      ),
      cell: ({ row }) => (
        <TranscriptsColumnsCell
          text={row?.original?.conversation_id ?? row?.original?._id}
          onClick={() =>
            addQueryParams({
              selected_transcript: row?.original?.conversation_id ?? row?.original?._id,
            })
          }
        />
      ),
    },
    {
      accessorKey: 'createdAt',
      header: ({ column }) => (
        <TranscriptsColumnsHeaderButton
          text={lang.get('msg.startTime')}
          onClick={() => toggleSortHandler(column)}
        />
      ),
      cell: ({ row }) => (
        <TranscriptsColumnsCell
          text={row?.getValue('createdAt')}
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
        />
      ),
    },
    {
      accessorKey: 'duration',
      header: ({ column }) => (
        <TranscriptsColumnsHeaderButton
          text={lang.get('msg.duration')}
          onClick={() => toggleSortHandler(column)}
        />
      ),
      cell: ({ row }) => (
        <TranscriptsColumnsCell
          text={row?.getValue('duration') !== 'NaN:NaN:NaN' ? row?.getValue('duration') : '/'}
          onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
        />
      ),
    },
    {
      accessorKey: 'digest.summary',
      header: ({ column }) => (
        <TranscriptsColumnsHeaderButton
          text={lang.get('msg.summary')}
          onClick={() => toggleSortHandler(column)}
        />
      ),
      cell: ({ row }) => (
        <TooltipWrapper
          text={row?.original?.digest?.summary || '/'}
          className="max-w-[700px] text-xs z-[9999999]"
        >
          <p className="w-[500px] truncate">{row?.original?.digest?.summary || '/'}</p>
        </TooltipWrapper>
      ),
    },
  ];

  if (metadataKeysArray.length) {
    metadataKeysArray.forEach((key: string) => {
      columns.push({
        accessorKey: `metadata.${key}`,
        header: ({ column }) => (
          <TranscriptsColumnsHeaderButton
            text={replaceUnderscoreWithSpace(key[0].toUpperCase() + key.slice(1))}
            onClick={() => toggleSortHandler(column)}
          />
        ),
        cell: ({ row }) => (
          <TranscriptsColumnsCell
            text={row.original.metadata?.[key] || '/'}
            onClick={() => addQueryParams({ selected_transcript: row?.original?._id })}
            additionalClasses="flex items-center justify-start w-full h-full"
          />
        ),
      });
    });
  }

  return columns;
};

export default TranscriptsColumns;
