import { useContext, useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { LangContext } from '@/context/LangProvider';
import { Textarea } from '@/components/ui/textarea';
import RequiredLabel from '@/components/labels/RequiredLabel';
import useCreateProjectStore from '@/store/CreateProjectStore';

interface ISpippetsProps {
  control: Control<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  register: UseFormRegister<FieldValues>;
  isUserViewer: boolean;
  isProjectPublished: boolean;
  isProjectCompleted: boolean;
  setValue: UseFormSetValue<FieldValues>;
}

function Snippets({
  control,
  watch,
  register,
  isUserViewer,
  isProjectPublished,
  isProjectCompleted,
  setValue,
}: ISpippetsProps) {
  const { lang } = useContext(LangContext);
  const { setIsFormChanged } = useCreateProjectStore();
  const [snippetsCharacters, setSnippetsCharacters] = useState(['']);

  const { fields, append, remove } = useFieldArray({
    name: 'snippets',
    control,
  });

  const watchFieldArray = watch('snippets');
  const controlledFields = watchFieldArray
    ? fields.map((field, index) => ({
        ...field,
        index,
      }))
    : [];

  const handleRemove = (index: number) => {
    const updatedSnippetsCharacters = [...snippetsCharacters];
    updatedSnippetsCharacters.splice(index, 1);
    setSnippetsCharacters(updatedSnippetsCharacters);
    remove(index);
  };

  useEffect(() => {
    if (watchFieldArray) {
      setSnippetsCharacters(watchFieldArray?.map((snippet: string) => snippet || ''));
    }

    if (!watchFieldArray?.length) {
      append('');
    }
  }, [watch, append, watchFieldArray]);

  return (
    <label className="flex flex-col gap-2">
      <div className="flex flex-col gap-4 mt-2">
        {controlledFields.map((field, index) => (
          <div className="flex flex-col justify-between w-full relative gap-1.5" key={field.id}>
            {/* remove btn */}
            {index > 0 && (
              <button
                onClick={() => handleRemove(index)}
                className="absolute right-0 p-0.5 text-white bg-red-700 rounded-full -top-5"
                type="button"
              >
                <IconX size={10} />
              </button>
            )}
            {/* content */}
            <RequiredLabel
              notRequired
              title="context"
              tooltip="It's important to provide Tellet's AI with useful contextual information that will make the interviews a success. Are their specific spellings of names or brands it should know? Or perhaps places, people, items or concepts that it should be aware of? What information would you provide to a human interviewer before it spoke with your customers?"
            >
              <Textarea
                disabled={isUserViewer || isProjectPublished || isProjectCompleted}
                {...register(`snippets.${index}`)}
                value={snippetsCharacters[index]}
                maxLength={1400}
                onChange={(e) => {
                  const updatedCharacters = [...snippetsCharacters];
                  updatedCharacters[index] = e.target.value;
                  setSnippetsCharacters(updatedCharacters);
                  setIsFormChanged(true);
                  setValue(`snippets.${index}`, e.target.value, { shouldDirty: true });
                }}
                className="h-24 rounded-xl"
                placeholder={lang.get('msg.contextPlaceholder')}
              />
            </RequiredLabel>
            {/* counter */}
            <p className="text-xs text-light-dark">
              {1400 -
                (snippetsCharacters[index] && snippetsCharacters[index].length
                  ? snippetsCharacters[index].length
                  : 0)}{' '}
              {lang.get('msg.charactersLeft')}
            </p>
          </div>
        ))}
      </div>
    </label>
  );
}

export default Snippets;
