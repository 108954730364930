import { create } from 'zustand';
import { AllTranscriptsProps } from '@/types';

type State = {
  translationsLoading: boolean;
  singleConversationTranslationLoading: boolean;
  isTranslating: boolean;
  singleConversationsBtnsDisabled: boolean;
  translatingProjects: [] | { project_id: string | undefined; language: string; length: number }[];
  originalTranscripts: [] | AllTranscriptsProps[];
  setOriginalTranscripts: (category: AllTranscriptsProps[] | []) => void;
};

type Action = {
  setTranslationsLoading: (category: boolean) => void;
  setSingleConversationTranslationLoading: (category: boolean) => void;
  setTranslating: (category: boolean) => void;
  setSingleConversationsBtnsDisabled: (category: boolean) => void;
  setTranslatingProjects: (
    category: [] | { project_id: string | undefined; language: string; length: number }[],
  ) => void;
  setOriginalTranscripts: (category: []) => void;
};

const useTranslationsLoadingStore = create<State & Action>((set) => ({
  translationsLoading: false,
  setTranslationsLoading: (loading) => set({ translationsLoading: loading }),
  isTranslating: false,
  setTranslating: (loading) => set({ isTranslating: loading }),
  singleConversationTranslationLoading: false,
  setSingleConversationTranslationLoading: (loading) =>
    set({ singleConversationTranslationLoading: loading }),
  singleConversationsBtnsDisabled: false,
  setSingleConversationsBtnsDisabled: (disabled) =>
    set({ singleConversationsBtnsDisabled: disabled }),
  translatingProjects: [],
  setTranslatingProjects: (projects) => set({ translatingProjects: projects }),
  originalTranscripts: [],
  setOriginalTranscripts: (transcripts) => set({ originalTranscripts: transcripts }),
}));

export default useTranslationsLoadingStore;
