import React from 'react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Routes from '@/router/routes';
import { EProjectStatus } from '@/enums';
import TooltipWrapper from '@/components/tooltip/TooltipWrapper';
import { NavBarLinksProps } from '@/types';
import { cn } from '@/lib/utils';

const projectPages = [
  {
    name: 'design',
    path: Routes.project_design.path,
  },
  {
    name: 'share',
    path: Routes.project_share.path,
  },
];

function NavBarLinks({
  resetTabs,
  organization_id,
  workspace_id,
  project_id,
  lang,
  routes,
  location,
  singleProjectData,
  isEmpathyTheme,
}: NavBarLinksProps) {
  const { currentTheme } = useTheme();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const language = searchParams.get('lang');
  const isProjectPublished = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const isProjectCompleted = singleProjectData?.status === EProjectStatus.COMPLETED;
  const extraParam = language ? `?lang=${language}` : '';
  return (
    <div className="flex items-center gap-7 max-w-[420px] w-full justify-center">
      {React.Children.toArray(
        projectPages.map(({ name, path }) => (
          <Link
            style={{ color: currentTheme?.['primary-text'] }}
            className="relative px-3.5 py-1.5 font-medium transition-all ease-out rounded-lg hover:bg-gray-100"
            to={`${routes.organization.path}/${organization_id}/workspace/${workspace_id}${path}/${project_id}${extraParam}`}
            onClick={resetTabs}
          >
            {lang.get(`msg.${name}`)}
            {location?.pathname.includes(path) && (
              <div
                style={{ backgroundColor: currentTheme?.primary }}
                className="h-[4px] absolute left-0 -bottom-[13px] w-full"
              ></div>
            )}
          </Link>
        )),
      )}
      <TooltipWrapper
        text={
          isEmpathyTheme
            ? lang.get(
                'msg.afterTelletIsCompletedOrPublishedYouWillBeAbleToViewResultsPage_empathy',
              )
            : lang.get('msg.afterTelletIsCompletedOrPublishedYouWillBeAbleToViewResultsPage')
        }
        className={cn(
          !isProjectPublished && !isProjectCompleted ? 'flex' : 'hidden',
          'relative max-w-[280px] text-center',
        )}
      >
        <div>
          <button
            disabled={!isProjectPublished && !isProjectCompleted}
            type="button"
            style={{ color: currentTheme?.['primary-text'] }}
            className={cn(
              'font-medium relative px-3.5 py-1.5 rounded-lg hover:bg-gray-100 transition-all ease-out ',
              !isProjectPublished && !isProjectCompleted && 'cursor-not-allowed opacity-70',
            )}
            onClick={() =>
              navigate(
                `${routes.organization.path}/${organization_id}/workspace/${workspace_id}${routes.project_results.path}/${project_id}${extraParam}`,
              )
            }
          >
            {lang.get('msg.results')}
            {location?.pathname.includes(routes.project_results.path) && (
              <div
                style={{ backgroundColor: currentTheme?.primary }}
                className="h-[4px] absolute -bottom-[13px] w-full left-0"
              ></div>
            )}
          </button>
        </div>
      </TooltipWrapper>
    </div>
  );
}

export default NavBarLinks;
