import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import Select from 'react-select';
import { CSS } from '@dnd-kit/utilities';
import { IconChevronDown, IconGripVertical, IconPlus } from '@tabler/icons-react';
import { Controller } from 'react-hook-form';
import useTheme from 'chat-ui/src/hooks/useTheme';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { LangContext } from '@/context/LangProvider';
import { cn } from '@/lib/utils';
import useQuestionsStore from '@/store/ScriptQuestionsStore';
import probingQuestions from './components/probingQuestionsList';
import QuestionMediaUploading from './components/QuestionMediaUploading';
import useCreateProjectStore from '@/store/CreateProjectStore';
import SparklesIcon from '@/components/icons/SparklesIcon';
import getTextColor from '@/utils/getTextColorContrast';
import { QuestionBoxData } from '@/types';
import { Input } from '@/components/ui/input';

function QuestionBox({
  id,
  register,
  index,
  control,
  handleDeleteQuestion,
  isActiveDrag,
  isUserViewer,
  existingText,
  isProjectPublished,
  questions,
  singleProjectData,
  isProjectCompleted,
  openQuestionId,
  setOpenQuestionId,
  setFileData,
  fileData,
  currentQuestion,
  setValue,
}: QuestionBoxData) {
  const { lang } = useContext(LangContext);
  const { isFormChanged, setIsFormChanged } = useCreateProjectStore();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });
  const [rerender, setRerender] = useState(true);
  const isOpen = openQuestionId === id;
  const { setQuestionsLength } = useQuestionsStore();
  const [toggleAiProbingFields, handleToggleAiProbingFields] = useState(
    !!currentQuestion?.probing_goal,
  );
  const [characterCount, setCharacterCount] = useState(existingText?.length || 0);
  const { currentTheme } = useTheme();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? '2px dashed #718096' : '1px solid #e2e8f0',
    opacity: isDragging ? 0.7 : 1,
    boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  };

  const handleDelete = () => {
    handleDeleteQuestion(id);
    setRerender(rerender);
  };

  const toggleQuestion = () => {
    setOpenQuestionId(isOpen ? null : id);
  };

  useLayoutEffect(() => {
    if (isActiveDrag) {
      setOpenQuestionId(null);
    }
  }, [isActiveDrag, setOpenQuestionId]);

  useEffect(() => {
    setQuestionsLength(questions?.length);
  }, [questions?.length]);

  useEffect(() => {
    if (!fileData[id]) {
      setFileData((prevFileData) => ({
        ...prevFileData,
        [id]: {
          id: '',
          file: undefined,
        },
      }));
    }
  }, [id, fileData, setFileData]);

  const hasProbingGoal = currentQuestion?.follow_up_question !== '0';
  const isDisabled = isUserViewer || isProjectPublished || isProjectCompleted;
  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-col w-full gap-5 bg-white border rounded-md shadow h-fit text-dark-text"
    >
      <button
        type="button"
        className="flex items-center justify-between w-full p-6"
        onClick={toggleQuestion}
      >
        {/* question title */}
        <h3 className="flex items-left w-[83%] font-medium ">
          {index + 1}
          {questions?.[index]?.question !== '' && <span>:</span>}
          {questions?.[index]?.question !== '' && (
            <span className="ml-1 text-left">{questions?.[index]?.question}</span>
          )}
        </h3>
        <div className="flex items-center gap-4">
          {/* probing questions number */}
          <p className="mx-1 text-sm">{currentQuestion.follow_up_question}</p>
          <span>
            <IconChevronDown
              size={20}
              className={`transition-all ease-in-out ${isOpen && 'rotate-180'}`}
            />
          </span>
          {/* drag item */}
          {!isUserViewer && !isProjectCompleted && !isProjectPublished && !isFormChanged && (
            <span className={`${!isOpen ? 'flex' : 'hidden'}`} {...attributes} {...listeners}>
              <IconGripVertical
                size={24}
                className="relative z-50 text-light-gray cursor-grabbing"
              />
            </span>
          )}
        </div>
      </button>
      <div className={cn('flex flex-col gap-5 p-6 pt-0', openQuestionId !== id && 'hidden')}>
        <RequiredLabel title="question" tooltip={lang.get('msg.questionInfo')}>
          <Textarea
            {...register(`interview_questions.${index}.question`)}
            onChange={(e) => {
              setCharacterCount(e.target.value.length);
              setIsFormChanged(true);
              setValue(`interview_questions.${index}.question`, e.target.value, {
                shouldDirty: true,
              });
            }}
            maxLength={700}
            disabled={isDisabled}
            placeholder={lang.get('msg.questionPlaceholder')}
            className="h-24"
          />
          <p className="text-sm text-gray-500">{characterCount} / 700 characters</p>
        </RequiredLabel>
        {/* uploading media */}
        <QuestionMediaUploading
          id={id}
          index={index}
          setFileData={setFileData}
          isProjectPublished={isProjectPublished}
          isProjectCompleted={isProjectCompleted}
          singleProjectData={singleProjectData}
          setValue={setValue}
        />

        <div className="flex flex-col gap-4">
          <div className="flex w-full flex-col gap-2">
            <div className="flex w-full gap-8 md700:flex-col">
              <div className="flex flex-col w-full gap-6 md700:w-full">
                {/* follow up question */}
                <RequiredLabel
                  icon={
                    <SparklesIcon
                      className="p-1 rounded-md size-6"
                      style={{
                        backgroundColor: currentTheme?.primary,
                        color: getTextColor(currentTheme?.primary || ''),
                      }}
                    />
                  }
                  title="aiProbingQuestions"
                  tooltip={lang.get('msg.probingQuestionInfo')}
                >
                  <Controller
                    name={`interview_questions.${index}.follow_up_question`}
                    control={control}
                    render={({ field }) => (
                      <div className="relative z-50 w-full">
                        <Select
                          value={probingQuestions.find(
                            (option) => option.value === currentQuestion.follow_up_question,
                          )}
                          onChange={(value) => {
                            field.onChange(value?.value);
                            setIsFormChanged(true);
                            setValue(
                              `interview_questions.${index}.follow_up_question`,
                              value?.value,
                              {
                                shouldDirty: true,
                              },
                            );
                          }}
                          options={probingQuestions}
                          isDisabled={isDisabled}
                          placeholder={field?.value}
                          styles={{
                            option: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused ? currentTheme?.primary : '#FFFFFF',
                              color: state.isFocused
                                ? getTextColor(currentTheme?.primary as string)
                                : '#000000',
                              ':active': {
                                backgroundColor: currentTheme?.primary,
                              },
                            }),
                            control: (styles) => ({
                              ...styles,
                              backgroundColor: isDisabled
                                ? currentTheme?.['input-invalid-color']
                                : '#FFFFFF',
                              borderColor: currentTheme?.['input-invalid-color'],
                            }),
                          }}
                        />
                      </div>
                    )}
                  />
                </RequiredLabel>
              </div>
            </div>
            {hasProbingGoal && (
              <>
                {!toggleAiProbingFields && !currentQuestion?.probing_goal && (
                  <Button
                    type="button"
                    variant="ghost"
                    className="flex w-auto ml-auto mt-2 items-center cursor-pointer px-3.5 py-2.5"
                    onClick={() => handleToggleAiProbingFields(!toggleAiProbingFields)}
                  >
                    <div className="text-sm flex items-center gap-2">
                      <IconPlus className="size-5" />
                      {lang.get('msg.addProbingGuide')}
                    </div>
                  </Button>
                )}
                <div className="flex flex-col gap-2">
                  {/* probing goal */}
                  {hasProbingGoal && toggleAiProbingFields && (
                    <div className="flex flex-col mt-2">
                      <RequiredLabel
                        notRequired
                        title="probingGuide"
                        tooltip={lang.get('msg.probingGuideInfo')}
                      >
                        <Controller
                          name={`interview_questions.${index}.probing_goal`}
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={lang.get('msg.probingGuidePlaceholder')}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setIsFormChanged(true);
                                setValue(
                                  `interview_questions.${index}.probing_goal`,
                                  e.target.value,
                                  {
                                    shouldDirty: true,
                                  },
                                );
                              }}
                              disabled={isDisabled}
                            />
                          )}
                        />
                      </RequiredLabel>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {questions?.length > 1 && (
          <Button
            disabled={isDisabled}
            variant="destructive"
            type="button"
            onClick={handleDelete}
            className="self-end"
          >
            {lang.get('msg.deleteQuestion')}
          </Button>
        )}
      </div>
    </div>
  );
}

export default QuestionBox;
