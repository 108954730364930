import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IconLoader2 } from '@tabler/icons-react';
import useTheme from 'chat-ui/src/hooks/useTheme';
import TranscriptsTable from './transcripts_table_data/TranscriptsTable';
import SingleTranscriptData from './SingleTranscriptData';
import useResultTabStore from '@/store/ResultsTabStore';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';
import { useGetTranslationLanguages } from '@/reactQuery/get';
import useTranslationsLoadingStore from '@/store/TranslationLoadingStore';
import { AllTranscriptsProps } from '@/types';

const REFETCH_INTERVAL = 10000;

function ConversationsTranscripts({ analysisLanguage }: { analysisLanguage: string }) {
  const { project_id } = useParams();
  const [searchParams] = useSearchParams();
  const { currentTheme } = useTheme();
  const selected_transcript = searchParams.get('selected_transcript');
  const filter_id = searchParams.get('filter_id');
  const activeTab = useResultTabStore((state) => state.activeTab);
  const language = searchParams.get('lang');
  const { data: languages } = useGetTranslationLanguages(project_id);
  const { translatingProjects, setOriginalTranscripts, originalTranscripts } =
    useTranslationsLoadingStore();
  const currentProjectIsTranslating = translatingProjects?.find(
    (project) => project.project_id === project_id,
  );

  const { data: originalConversations } = useQuery<AllTranscriptsProps[]>({
    queryFn: () =>
      axiosInstanceAnalyze
        .get(
          `/analyzer/results/${project_id}/get_conversations${
            filter_id ? `?filter_id=${filter_id}` : ''
          }`,
        )
        .then((response) => response.data),
    queryKey: ['original_conversations', { filter_id, project_id }],
    enabled: activeTab === 'transcripts_tab' && !!project_id,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
  });

  const {
    data: allConversations,
    isLoading,
    isFetching,
    refetch,
  } = useQuery<AllTranscriptsProps[]>({
    queryFn: () =>
      axiosInstanceAnalyze
        .get(
          `/analyzer/results/${project_id}/get_conversations${
            filter_id || language
              ? `?${filter_id ? `filter_id=${filter_id}` : ''}${filter_id && languages ? '&' : ''}${
                  language ? `language=${language}` : ''
                }`
              : ''
          }`,
        )
        .then((response) => response.data),
    queryKey: ['single_conversations_data', { filter_id, project_id, languages }],
    select: (data) => data,
    enabled: activeTab === 'transcripts_tab' && !!project_id,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: currentProjectIsTranslating ? REFETCH_INTERVAL : 0,
  });

  useEffect(() => {
    if ((language || analysisLanguage) && !selected_transcript) {
      refetch();
    }
  }, [language, analysisLanguage, refetch, selected_transcript]);

  const selectedTranscript = allConversations?.find((item) => item?._id === selected_transcript);
  const allConversationsLength = allConversations?.length;
  const isLanguageForGoodTranscripts = allConversations?.[0]?.language === language;
  const originalTranscriptsLength = originalTranscripts?.length;
  const originalConversationsLength = originalConversations?.length;

  useEffect(() => {
    if (!!originalConversations && originalTranscriptsLength !== originalConversationsLength) {
      setOriginalTranscripts([...originalConversations]);
    }
  }, [originalConversations, originalConversationsLength, setOriginalTranscripts]);

  if ((isLoading || isFetching) && !allConversations) {
    return (
      <div className="flex items-center mx-auto max-w-[1300px] justify-center w-full h-full p-40">
        <IconLoader2 style={{ color: currentTheme?.primary }} className="animate-spin" size={30} />
      </div>
    );
  }

  const translatedLanguages = [
    analysisLanguage?.replace(/.*/, 'Original') || 'Original',
    ...(languages || []),
  ];

  const sortedConversations = allConversations?.sort(
    (a, b) => a.respondent_index - b.respondent_index,
  );

  const mergeConversationsWithOriginals = (
    conversations: AllTranscriptsProps[] | undefined,
    transcripts: AllTranscriptsProps[] | undefined,
  ) => {
    if (!conversations || !transcripts) return conversations;
    return conversations.map((conv) => {
      if (conv.language) {
        const original = transcripts.find((orig) => orig._id === conv.conversation_id);
        return original ? { ...original, ...conv, createdAt: original.createdAt } : conv;
      }
      return conv;
    });
  };

  const mergedConversations = mergeConversationsWithOriginals(
    sortedConversations,
    originalTranscripts,
  );

  return (
    <div className="w-full mx-auto">
      {!selected_transcript ? (
        <TranscriptsTable
          allConversations={mergedConversations}
          project_id={project_id}
          analysisLanguage={analysisLanguage}
          languages={languages}
          allConversationsLength={allConversationsLength}
          initialTranslatedLanguages={translatedLanguages}
          isLanguageForGoodTranscripts={isLanguageForGoodTranscripts}
        />
      ) : (
        <SingleTranscriptData
          selectedTranscript={selectedTranscript}
          allConversationsLength={allConversationsLength}
          allConversations={mergedConversations}
          analysisLanguage={analysisLanguage}
          languages={languages}
          project_id={project_id}
          initialTranslatedLanguages={translatedLanguages}
          isLanguageForGoodTranscripts={isLanguageForGoodTranscripts}
        />
      )}
    </div>
  );
}

export default ConversationsTranscripts;
