import Lang from 'lang.js';

const getHTMLTextOnPublishButtonHover = (emptyFieldsLabels: string[], lang: Lang): string => {
  return emptyFieldsLabels.length > 0
    ? `<p class="text-left my-1 font-semibold ">${lang.get(
        'msg.followingFieldsAreEmpty',
      )}</p> ${emptyFieldsLabels
        .map((label) => `<li class="text-left text-[13px]">${lang.get(`msg.${label}`)}</li>`)
        .join(' ')} <p class="text-left my-1 font-semibold text-[13px]">${lang.get(
        'msg.pleaseFillTheEmptyFieldsToContinue',
      )}</p>`
    : lang.get('msg.allFieldsAreFilledOut');
};

export default getHTMLTextOnPublishButtonHover;
