import { create } from 'zustand';

type State = {
  screeningQeustionsLength: number;
};

type Action = {
  setScreeningQeustionsLength: (value: number) => void;
};

const useScreeningQuestionsStore = create<State & Action>((set) => ({
  screeningQeustionsLength: 1,
  setScreeningQeustionsLength: (value: number) => set({ screeningQeustionsLength: value }),
}));

export default useScreeningQuestionsStore;
