import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { TooltipProps } from '@/types';

function TooltipWrapper({ children, text, className }: TooltipProps) {
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(text);
  return (
    <TooltipProvider>
      <Tooltip delayDuration={50}>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className={className}>
          {isHTML ? <p dangerouslySetInnerHTML={{ __html: text }} /> : <p>{text}</p>}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export default TooltipWrapper;
