import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { IconPlus } from '@tabler/icons-react';
import { v4 as uuidv4 } from 'uuid';
import ScreeningMultiAndSingleSelectItem from './ScreeningMultiAndSingleSelectItem';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import useScreeningQuestionsStore from '@/store/ScreeningQuestionsStore';
import { Button } from '@/components/ui/button';
import { MultiSelectAnswerProps } from '@/types';
import { ScreeningRulesTypes } from '@/enums';
import useTranslation from '@/hooks/useTranslation';

const MAX_ANSWERS = 10;

function ScreeningMultiAndSingleSelectAnswer({
  questionId,
  responses,
  isProjectCompleted,
  isProjectPublished,
  itemType,
  handleQuestionAnswerChange,
  handleDeleteAnswer,
  handleAnswersOrderChange,
  setIsFormChanged,
}: MultiSelectAnswerProps) {
  const { workspace_id } = useParams();
  const [answers, setAnswers] = useState(responses);
  const [isActiveDrag, setIsActiveDrag] = useState(false);
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { setScreeningQeustionsLength } = useScreeningQuestionsStore();
  const { lang } = useTranslation();

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over || active.id === over.id) return;

    setIsFormChanged(true);

    setAnswers((prevAnswers) => {
      const activeIndex = prevAnswers.findIndex((q) => q.id === active.id);
      const overIndex = prevAnswers.findIndex((q) => q.id === over.id);
      const reorderedAnswers = arrayMove(prevAnswers, activeIndex, overIndex);
      handleAnswersOrderChange(questionId, reorderedAnswers);
      return reorderedAnswers;
    });

    setIsActiveDrag(false);
  };

  const addAnswer = () => {
    const newAnswer = {
      id: uuidv4(),
      something: 'something',
      value: '',
      rule: ScreeningRulesTypes.QUALIFY,
    };

    // Update the state
    setAnswers((prevAnswers) => [...(prevAnswers || []), newAnswer]);
    setIsFormChanged(true);
    handleQuestionAnswerChange(questionId, newAnswer.id, newAnswer.value, newAnswer.rule);
  };

  const handleDeleteQuestion = (idToDelete: string) => {
    // Update the state
    setAnswers((prevAnswer) => prevAnswer.filter((question) => question.id !== idToDelete));

    handleDeleteAnswer(questionId, idToDelete);
  };

  useEffect(() => {
    setScreeningQeustionsLength(answers?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answers?.length]);

  useEffect(() => {
    setAnswers(responses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responses]);

  const isDisabled = isUserViewer || isProjectPublished || isProjectCompleted;
  const isButtonDisabled = isDisabled || answers?.length >= MAX_ANSWERS;

  return (
    <div className="flex flex-col w-full h-full">
      <div className="flex items-center w-full h-full">
        <div className="flex flex-col w-full h-full">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            onDragStart={() => setIsActiveDrag(true)}
          >
            <div className="flex flex-col w-full h-full gap-3 p-6 mt-2 mb-5 bg-gray-100 rounded-md">
              <SortableContext
                disabled={isDisabled}
                items={answers}
                strategy={verticalListSortingStrategy}
              >
                {answers?.map((answer, index, arr) => (
                  <ScreeningMultiAndSingleSelectItem
                    answer={answer}
                    isActiveDrag={isActiveDrag}
                    key={answer.id}
                    id={answer.id}
                    questionId={questionId}
                    openQuestionId={openQuestionId}
                    setOpenQuestionId={setOpenQuestionId}
                    isProjectCompleted={isProjectCompleted}
                    isProjectPublished={isProjectPublished}
                    isUserViewer={isUserViewer}
                    index={index}
                    handleDeleteQuestion={handleDeleteQuestion}
                    handleQuestionAnswerChange={handleQuestionAnswerChange}
                    itemType={itemType}
                    totalNumberOfAnswers={arr?.length}
                  />
                ))}
              </SortableContext>
            </div>
          </DndContext>
          <Button
            type="button"
            onClick={addAnswer}
            disabled={isButtonDisabled}
            variant="default"
            className="flex items-center self-start gap-1 text-xs"
          >
            <IconPlus size={13} />
            {lang.get('msg.addNewAnswer')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default ScreeningMultiAndSingleSelectAnswer;
