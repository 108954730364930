import { ReactNode, useRef, useState } from 'react';
import useOnClickOutside from 'chat-ui/src/hooks/useClickoutside';
import { screeningQuestionTypeIcon, screeningQuestionTypeOptions } from './screeningQuestionTypes';
import { cn } from '@/lib/utils';
import { ScreeningQuestionType } from '@/enums';
import useTranslation from '@/hooks/useTranslation';

const questionDescriptions: Record<ScreeningQuestionType, { title: string; description: string }> =
  {
    [ScreeningQuestionType.MULTI_SELECT]: {
      title: 'whenToUseMultiSelect',
      description: 'whenToUseMultiSelectDescription',
    },
    [ScreeningQuestionType.SINGLE_SELECT]: {
      title: 'whenToUseSingleSelect',
      description: 'whenToUseSingleSelectDescription',
    },
    [ScreeningQuestionType.NUMERIC]: {
      title: 'whenToUseNumeric',
      description: 'wehenToUseNumericDescription',
    },
  };

function Description({ type }: { type: ScreeningQuestionType }) {
  const { lang } = useTranslation();

  return (
    <div className="flex flex-col w-full gap-3">
      <h4 className="font-semibold">{lang.get(`msg.${questionDescriptions[type].title}`)}</h4>
      <p className="text-xs font-medium">
        {lang.get(`msg.${questionDescriptions[type].description}`)}
      </p>
    </div>
  );
}

function ScreeningTypesInfo() {
  const popupRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showTypeDescriptionFor, setShowTypeDescriptionFor] = useState(
    ScreeningQuestionType.MULTI_SELECT,
  );
  const { lang } = useTranslation();
  const toggleInfo = () => setIsInfoOpen((prev) => !prev);

  useOnClickOutside(popupRef, (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setIsInfoOpen(false);
    }
  });

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={toggleInfo}
        type="button"
        className="w-5 h-5 text-[13px] text-blue-600 bg-blue-200 rounded-full"
      >
        ?
      </button>

      {isInfoOpen && (
        <div
          ref={popupRef}
          className="flex absolute z-100 h-[250px] right-[430px] top-[25px] w-full"
        >
          <div className="flex flex-col p-6 bg-white min-w-[200px] border-y-2 border-l-2 border-black/20">
            <p className="font-semibold text-gray-400 uppercase whitespace-nowrap">
              {lang.get('msg.questionType')}
            </p>
            <div className="flex flex-col items-start w-full gap-5 mt-5 font-semibold text-gray-400 whitespace-nowrap">
              {screeningQuestionTypeOptions?.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setShowTypeDescriptionFor(item.title)}
                  className={cn(
                    'flex items-center gap-2 transition-all hover:text-black',
                    showTypeDescriptionFor === item.title && 'text-black',
                  )}
                  type="button"
                >
                  {screeningQuestionTypeIcon[item.title] as ReactNode}
                  <p>
                    {item?.title === ScreeningQuestionType.MULTI_SELECT && 'Multi-select'}
                    {item?.title === ScreeningQuestionType.SINGLE_SELECT && 'Single-select'}
                    {item?.title === ScreeningQuestionType.NUMERIC && 'Numeric'}
                  </p>
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col p-6 bg-green-100 min-w-[250px] border-y-2 border-r-2 border-black/20">
            <Description type={showTypeDescriptionFor} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ScreeningTypesInfo;
