import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import useTheme from 'chat-ui/src/hooks/useTheme';
import { useParams } from 'react-router-dom';
import axiosInstance from '@/axios/axiosInstance';
import { ECompanyThemeName, EProjectStatus, EStatusCode } from '@/enums';
import useOrganizationRoles from '@/hooks/useOrganizationRoles';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import { cn } from '@/lib/utils';
import { useMutateApprovePublishingProject, useMutatePublishProject } from '@/reactQuery/post';
import CompleteProjectModal from '../Modals/CompleteProjectModal';
import RequestPublishingModal from '../Modals/RequestPublishingModal';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { Button } from '../ui/button';
import { toast } from '../ui/use-toast';
import useProjectRequiredFieldsStore from '@/store/ProjectRequiredFieldsStore';
import getHTMLTextOnPublishButtonHover from '@/utils/getHTMLTextOnPublishButtonHover';

function ProjectButtonScenarios() {
  const { project_id, workspace_id } = useParams();
  const { lang } = useTranslation();
  const { themeName } = useTheme();
  const { emptyFieldsLabels } = useProjectRequiredFieldsStore();
  const [publishTooltipText, setPublishTooltipText] = useState<string>('');
  const {
    isUserAdmin: isUserAdminOrg,
    isUserEditor: isUserEditorOrg,
    isUserOwner: isUserOwnerOrg,
  } = useOrganizationRoles();

  const { data: singleProjectData } = useQuery({
    queryFn: () => axiosInstance.get(`projects/get/${project_id}`),
    queryKey: ['single_project_data', { project_id }],
    select: (data) => data?.data,
  });

  const {
    isUserViewer: isUserViewerWorkspace,
    isUserOwner: isUserOwnerWorkspace,
    isUserEditor: isUserEditorWorkspace,
  } = useWorkspaceRoles(workspace_id);

  // -- helpers --

  // statuses
  const is_status_draft: boolean = singleProjectData?.status === EProjectStatus.DRAFT;
  const is_status_ready: boolean = singleProjectData?.status === EProjectStatus.READY;
  const is_status_request_approved: boolean =
    singleProjectData?.status === EProjectStatus.REQUEST_APPROVED;
  const is_status_waiting_approval: boolean =
    singleProjectData?.status === EProjectStatus.WAITING_APPROVAL;
  const is_status_published: boolean = singleProjectData?.status === EProjectStatus.PUBLISHED;
  const is_status_completed: boolean = singleProjectData?.status === EProjectStatus.COMPLETED;

  // role groups
  const is_ORGANIZATION_owner_or_admin: boolean = isUserOwnerOrg || isUserAdminOrg;

  // -- BUTTON SCENARIOS --
  const PUBLISH_SCENARIO: boolean =
    ((is_status_draft || is_status_ready) && is_ORGANIZATION_owner_or_admin) ||
    (isUserEditorOrg &&
      (isUserOwnerWorkspace || isUserEditorWorkspace) &&
      is_status_request_approved);

  const REQUEST_TO_PUBLISH_SCENARIO: boolean =
    isUserEditorOrg && (is_status_ready || is_status_draft);

  const REQUEST_SENT_SCENARIO: boolean =
    isUserEditorOrg && !isUserViewerWorkspace && is_status_waiting_approval;

  const APPROVE_PUBLISHING_SCENARIO: boolean = is_ORGANIZATION_owner_or_admin && !PUBLISH_SCENARIO;

  const PROJECT_PUBLISHED_SCENARIO: boolean = is_status_published;

  const PROJECT_COMPLETED_SCENARIO: boolean = is_status_completed;

  // FUNCTIONS

  const { mutateAsync: mutateAsyncPublishProject } = useMutatePublishProject();
  const { mutateAsync: mutateAsyncApprovePublishingProject } = useMutateApprovePublishingProject();

  const publishProjectFunction = async (formData: string | undefined) => {
    try {
      await mutateAsyncPublishProject(formData);
      toast({
        description: `${lang.get('msg.project')} "${singleProjectData?.title}" ${lang.get(
          'msg.isSuccessfullyPublished',
        )}`,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.NOT_SUBSCRIBED) {
        toast({
          description:
            themeName === ECompanyThemeName.EMPATHY
              ? lang.get('msg.projectSubscriptionPublishError_empathy')
              : lang.get('msg.projectSubscriptionPublishError'),
        });
      } else {
        toast({
          variant: 'destructive',
          description: lang.get('msg.projectIsNotPublishedPleaseTryAgain'),
        });
      }
    }
  };

  const approvePublishingProjectFunction = async (formData: string | undefined) => {
    try {
      await mutateAsyncApprovePublishingProject(formData);
      toast({ description: lang.get('msg.projectSuccessfullyApproved') });
    } catch (error) {
      toast({ variant: 'destructive', description: 'Error. Please try agian.' });
    }
  };

  useEffect(() => {
    setPublishTooltipText(getHTMLTextOnPublishButtonHover(emptyFieldsLabels, lang));
  }, [emptyFieldsLabels, lang]);

  return (
    <div className="flex items-center justify-end gap-4 w-fit">
      {/* PUBLISH */}
      {PUBLISH_SCENARIO && !PROJECT_PUBLISHED_SCENARIO && (
        <TooltipWrapper
          text={publishTooltipText}
          className={cn(
            is_status_draft ? 'flex' : 'hidden',
            'relative max-w-[360px] text-center mr-10',
          )}
        >
          <div>
            <Button
              variant="default"
              onClick={() => publishProjectFunction(project_id)}
              disabled={is_status_draft}
              type="button"
              className="h-8 px-5"
            >
              {PUBLISH_SCENARIO && !is_status_request_approved && lang.get('msg.publish')}
              {is_status_request_approved && lang.get('msg.requestApproved')}
            </Button>
          </div>
        </TooltipWrapper>
      )}

      {/* PROJECT PUBLISHED SCENARIO */}
      {PROJECT_PUBLISHED_SCENARIO && (
        <div className="flex items-center gap-3">
          <Button variant="default" disabled type="button" className="h-8 px-5">
            {lang.get('msg.projectPublished')}
          </Button>

          <CompleteProjectModal />
        </div>
      )}

      {/* REQUEST TO PUBLISH */}
      {REQUEST_TO_PUBLISH_SCENARIO && (
        <RequestPublishingModal requestAgain={false} is_status_draft={is_status_draft} />
      )}

      {/* APPROVE PUBLISHING */}
      {APPROVE_PUBLISHING_SCENARIO && is_status_waiting_approval && (
        <Button
          variant="default"
          onClick={() => approvePublishingProjectFunction(project_id)}
          disabled={isUserEditorOrg && isUserViewerWorkspace}
          type="button"
          className="h-8 px-5"
        >
          {lang.get('msg.approvePublishing')}
        </Button>
      )}

      {APPROVE_PUBLISHING_SCENARIO && is_status_request_approved && (
        <Button variant="default" disabled type="button" className="h-8 px-5">
          {lang.get('msg.approved')}
        </Button>
      )}

      {PROJECT_COMPLETED_SCENARIO && (
        <Button variant="default" disabled type="button" className="h-8 px-5">
          {lang.get('msg.completed')}
        </Button>
      )}

      {/* REQUEST SENT + REQUEST AGAIN */}
      {REQUEST_SENT_SCENARIO && (
        <div className="flex items-center gap-2">
          <Button variant="default" disabled type="button" className="h-8 px-5">
            {lang.get('msg.requestSent')}
          </Button>
          {isUserEditorOrg && !isUserViewerWorkspace && is_status_waiting_approval && (
            <RequestPublishingModal requestAgain is_status_draft={is_status_draft} />
          )}
        </div>
      )}
    </div>
  );
}

export default ProjectButtonScenarios;
