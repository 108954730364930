import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconPlus } from '@tabler/icons-react';
import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { v4 as uuidv4 } from 'uuid';
import { Button } from '@/components/ui/button';
import useTranslation from '@/hooks/useTranslation';
import useWorkspaceRoles from '@/hooks/useWorkspaceRoles';
import useCreateProjectStore from '@/store/CreateProjectStore';
import ScreeningQuestionBox from './components/ScreeningQuestionBox';
import { ScreeningQuestionType, ScreeningRulesTypes } from '@/enums';
import { ScreeningProps, ScreeningQuestion, ScreeningResponse } from '@/types';

const singleSelectDefaultAnswers = [
  { id: uuidv4(), value: '', rule: ScreeningRulesTypes.QUALIFY },
  { id: uuidv4(), value: '', rule: ScreeningRulesTypes.DISQUALIFY },
];

const multiSelectDefaultAnswers = [
  { id: uuidv4(), value: '', rule: ScreeningRulesTypes.QUALIFY },
  { id: uuidv4(), value: '', rule: ScreeningRulesTypes.IRRELEVANT },
  { id: uuidv4(), value: '', rule: ScreeningRulesTypes.DISQUALIFY },
];

const numericDefaultAnswers = {};

const MAX_QUESTIONS = 5;

function ScreeningQuestions({
  isProjectPublished,
  isProjectCompleted,
  setValue,
  onSubmit,
  screeningQuestions,
  register,
}: ScreeningProps) {
  const { lang } = useTranslation();
  const { workspace_id } = useParams();
  const [questions, setQuestions] = useState<ScreeningQuestion[]>(screeningQuestions || []);
  const [isActiveDrag, setIsActiveDrag] = useState(false);
  const [openQuestionId, setOpenQuestionId] = useState<string | null>(null);
  const { isUserViewer } = useWorkspaceRoles(workspace_id);
  const { setReorderChanged } = useCreateProjectStore();
  const { setIsFormChanged } = useCreateProjectStore();

  const updateQuestions = (updatedQuestions: ScreeningQuestion[]) => {
    setQuestions(updatedQuestions);
    setValue('screening_questions', updatedQuestions, { shouldDirty: true });
  };

  const updateQuestionById = (
    questionId: string,
    updater: (question: ScreeningQuestion) => ScreeningQuestion,
  ) => {
    const updatedQuestions = questions.map((question) =>
      question.id === questionId ? updater(question) : question,
    );
    updateQuestions(updatedQuestions);
  };

  const getResponsesForType = (newType: ScreeningQuestionType) => {
    const responseActions = {
      [ScreeningQuestionType.SINGLE_SELECT]: singleSelectDefaultAnswers,
      [ScreeningQuestionType.MULTI_SELECT]: multiSelectDefaultAnswers,
    };

    return responseActions[newType as keyof typeof responseActions] || [];
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) return;

    setQuestions((prevQuestions) => {
      const oldIndex = prevQuestions.findIndex((q) => q.id === active.id);
      const newIndex = prevQuestions.findIndex((q) => q.id === over.id);

      const reorderedQuestions = arrayMove(prevQuestions, oldIndex, newIndex);
      setValue('screening_questions', reorderedQuestions, { shouldDirty: true });
      setReorderChanged(true);

      return reorderedQuestions;
    });

    setIsActiveDrag(false);
  };

  const handleAddNewQuestion = () => {
    const newQuestion: ScreeningQuestion = {
      id: uuidv4(),
      question: '',
      type: ScreeningQuestionType.MULTI_SELECT,
      responses: [...multiSelectDefaultAnswers],
    };
    updateQuestions([...(questions || []), newQuestion]);
  };

  const handleQuestionTextChange = (questionId: string, newValue: string) => {
    updateQuestionById(questionId, (question) => ({ ...question, question: newValue }));
  };

  const handleAnswersOrderChange = (questionId: string, answers: ScreeningResponse[]) => {
    updateQuestionById(questionId, (question) => ({
      ...question,
      responses: answers,
    }));
  };

  const handleQuestionTypeChange = (questionId: string, newType: ScreeningQuestionType) => {
    updateQuestionById(questionId, ({ id, question }) => ({
      id,
      question,
      type: newType,
      ...(newType === ScreeningQuestionType.NUMERIC
        ? { numericResponse: numericDefaultAnswers }
        : { responses: getResponsesForType(newType) }),
    }));
  };

  const handleQuestionAnswerChange = (
    questionId: string,
    answerId: string,
    newText: string | { minimum?: number; maximum?: number },
    newRule: ScreeningRulesTypes | undefined,
  ) => {
    updateQuestionById(questionId, (question) => {
      if (question.type === ScreeningQuestionType.NUMERIC) {
        return {
          ...question,
          numericResponse: {
            ...question.numericResponse,
            ...(typeof newText !== 'string' &&
              newText.minimum !== undefined && { minimum: +newText.minimum }),
            ...(typeof newText !== 'string' &&
              newText.maximum !== undefined && { maximum: +newText.maximum }),
          },
        };
      }
      // For non-numeric types
      const responses = question.responses || [];
      const responseExists = responses.some((response) => response.id === answerId);

      return {
        ...question,
        responses: responseExists
          ? responses.map((response) =>
              response.id === answerId
                ? {
                    ...response,
                    value: newText as string,
                    rule: newRule,
                  }
                : response,
            )
          : [
              ...responses,
              {
                id: answerId,
                rule: newRule,
                value: newText as string,
              },
            ],
      };
    });
  };

  const handleDeleteAnswer = (questionId: string, answerId: string) => {
    setIsFormChanged(true);
    const questionIndex = questions.findIndex((q) => q.id === questionId);
    const responseIndex = questions[questionIndex]?.responses?.findIndex((r) => r.id === answerId);
    let screeningResponse: ScreeningResponse[] = [];
    const updatedQuestions = questions.map((question) => {
      if (question.id === questionId) {
        screeningResponse = (question.responses || []).filter(
          (response) => response.id !== answerId,
        );
        return {
          ...question,
          responses: screeningResponse,
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    setValue('screening_questions', updatedQuestions, { shouldDirty: true });
    setValue(`screening_questions.${questionIndex}.responses.${responseIndex}`, screeningResponse, {
      shouldDirty: true,
    });

    if (
      questions.some((q) => {
        if (q.id === questionId && q.type !== ScreeningQuestionType.NUMERIC) {
          const responses = q.responses || [];
          return responses.length < 2;
        }
        return false;
      })
    ) {
      setIsFormChanged(false);
    }
  };

  const handleDeleteQuestion = (idToDelete: string) => {
    setQuestions((prevQuestions) => prevQuestions.filter((question) => question.id !== idToDelete));
    const updatedInterviewQuestions =
      questions?.filter((q: { id: string }) => q.id !== idToDelete) || [];
    setValue('screening_questions', updatedInterviewQuestions, { shouldDirty: true });
    onSubmit({ screening_questions: updatedInterviewQuestions }, true);
  };

  useEffect(() => {
    setValue('screening_questions', questions, { shouldDirty: true });
  }, [questions, setValue]);

  useEffect(() => {
    if (screeningQuestions) {
      setQuestions(screeningQuestions);
    }
  }, [screeningQuestions]);

  const isAddScreeningQuestionDisabled = isProjectPublished || isProjectCompleted;
  const isButtonDisabled = isAddScreeningQuestionDisabled || questions?.length >= MAX_QUESTIONS;
  const isSortableDisabled = isUserViewer || isAddScreeningQuestionDisabled;
  const hasQuestions = questions?.length > 0;

  return (
    <div className="flex flex-col flex-grow w-full max-h-full">
      <div className="flex flex-col w-full">
        <h4 className="flex items-center gap-1 text-[15px] font-medium">
          {lang.get('msg.screeningQuestions')}
        </h4>
      </div>

      {/* questions area */}
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={() => setIsActiveDrag(true)}
      >
        <div className="flex flex-col w-full h-full gap-5 p-6 my-5 rounded-md bg-slate-50">
          {!hasQuestions && (
            <p className="text-sm font-medium">{lang.get('msg.noScreeningQuestions')}</p>
          )}

          <SortableContext
            disabled={isSortableDisabled}
            items={questions}
            strategy={verticalListSortingStrategy}
          >
            {questions?.map((question, index) => {
              const responses =
                question.type === ScreeningQuestionType.NUMERIC
                  ? question.numericResponse
                  : question.responses;
              return (
                <ScreeningQuestionBox
                  key={question.id}
                  index={index}
                  isActiveDrag={isActiveDrag}
                  id={question.id}
                  type={question.type}
                  question_text={question.question}
                  responses={responses}
                  openQuestionId={openQuestionId}
                  isProjectCompleted={isProjectCompleted}
                  isProjectPublished={isProjectPublished}
                  isUserViewer={isUserViewer}
                  register={register}
                  setOpenQuestionId={setOpenQuestionId}
                  handleDeleteQuestion={handleDeleteQuestion}
                  handleQuestionTextChange={handleQuestionTextChange}
                  handleQuestionTypeChange={handleQuestionTypeChange}
                  handleQuestionAnswerChange={handleQuestionAnswerChange}
                  handleDeleteAnswer={handleDeleteAnswer}
                  handleAnswersOrderChange={handleAnswersOrderChange}
                />
              );
            })}
          </SortableContext>
        </div>
      </DndContext>
      <Button
        onClick={handleAddNewQuestion}
        disabled={isButtonDisabled}
        variant="default"
        type="button"
        className="mb-2"
      >
        <IconPlus size={16} className="mr-1" />
        {lang.get('msg.addScreeningQuestion')}
      </Button>
    </div>
  );
}

export default ScreeningQuestions;
