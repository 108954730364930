import { ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconGripVertical, IconChevronDown } from '@tabler/icons-react';
import useOnClickOutside from 'chat-ui/src/hooks/useClickoutside';
import { cn } from '@/lib/utils';
import RequiredLabel from '@/components/labels/RequiredLabel';
import { Textarea } from '@/components/ui/textarea';
import ScreeningTypesInfo from './ScreeningTypesInfo';
import { screeningQuestionTypeIcon, screeningQuestionTypeOptions } from './screeningQuestionTypes';
import { Input } from '@/components/ui/input';
import ScreeningMultiAndSingleSelectAnswer from './ScreeningMultiAndSingleSelectAnswer';
import { Button } from '@/components/ui/button';
import useCreateProjectStore from '@/store/CreateProjectStore';
import { ScreeningQuestionBoxProps, ScreeningResponse } from '@/types';
import { ScreeningQuestionType } from '@/enums';
import useTranslation from '@/hooks/useTranslation';

function ScreeningQuestionBox({
  index,
  isActiveDrag,
  id,
  responses,
  openQuestionId,
  setOpenQuestionId,
  isUserViewer,
  isProjectCompleted,
  isProjectPublished,
  handleDeleteQuestion,
  question_text,
  type,
  register,
  handleQuestionTextChange,
  handleQuestionTypeChange,
  handleQuestionAnswerChange,
  handleDeleteAnswer,
  handleAnswersOrderChange,
}: ScreeningQuestionBoxProps) {
  const isNumeric = type === ScreeningQuestionType.NUMERIC;
  const isNumericAndResponses = isNumeric && responses && !Array.isArray(responses);
  const { lang } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { isFormChanged, setIsFormChanged, setIsProjectCreateButtonDisabled } =
    useCreateProjectStore();
  const isOpen = openQuestionId === id;
  const [questionType, setQuestionType] = useState(type);
  const [isQuestionTypeOpen, setIsQuestionTypeOpen] = useState(false);
  const [minimumValue, setMinimumValue] = useState(isNumericAndResponses ? responses.minimum : '');
  const [maximumValue, setMaximumValue] = useState(isNumericAndResponses ? responses.maximum : '');
  const [validationError, setValidationError] = useState<string | null>(null);
  const handleQuestionTypeSelect = () => {
    setIsQuestionTypeOpen(!isQuestionTypeOpen);
  };

  const handleChangingQuestionType = (screenQuestionType: ScreeningQuestionType) => {
    setQuestionType(screenQuestionType);
    handleQuestionTypeChange(id, screenQuestionType);
    setIsFormChanged(true);
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? '2px dashed #718096' : '1px solid #e2e8f0',
    opacity: isDragging ? 0.7 : 1,
    boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  };

  const toggleQuestion = () => {
    setOpenQuestionId(isOpen ? null : id);
  };

  useLayoutEffect(() => {
    if (isActiveDrag) {
      setOpenQuestionId(null);
    }
  }, [isActiveDrag, setOpenQuestionId]);

  useOnClickOutside(dropdownRef, (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setIsQuestionTypeOpen(false);
    }
  });

  const isDisabled = isUserViewer || isProjectCompleted || isProjectPublished;
  const canDrag = !isUserViewer && !isProjectCompleted && !isProjectPublished && !isFormChanged;

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex flex-col w-full p-4 bg-white border rounded-md shadow"
    >
      {/* Header */}
      <button
        type="button"
        className="flex items-center justify-between w-full px-5 py-1"
        onClick={toggleQuestion}
      >
        <h3 className="font-medium">
          {index + 1}. {lang.get('msg.question')}: {question_text}
        </h3>
        <div className="flex items-center gap-2">
          <span>
            <IconChevronDown
              size={20}
              className={`transition-all ease-in-out ${isOpen && 'rotate-180'}`}
            />
          </span>
          {/* drag item */}
          {canDrag && (
            <span className={`${!isOpen ? 'flex' : 'hidden'}`} {...attributes} {...listeners}>
              <IconGripVertical
                size={24}
                className="relative z-50 text-light-gray cursor-grabbing"
              />
            </span>
          )}
        </div>
      </button>

      {/* Body */}
      <div className={cn('flex flex-col w-full px-5 pt-6', openQuestionId !== id && 'hidden')}>
        {/* Question */}
        <RequiredLabel title="question">
          <Textarea
            {...register(`screening_questions.${index}.question`)}
            onChange={(e) => {
              setIsFormChanged(true);
              handleQuestionTextChange(id, e.target.value);
            }}
            disabled={isDisabled}
            defaultValue={question_text}
            className="min-h-16"
            placeholder={lang.get('msg.screeningQuestionPlaceholder')}
          />
        </RequiredLabel>

        {/* Question type */}
        <div className="flex flex-col w-full gap-3 mt-7">
          {/* Custom select dropdown */}
          <div className="flex flex-col items-start w-full gap-2 text-[15px]">
            <div className="flex items-center justify-between w-full gap-2 font-medium">
              <p>{lang.get('msg.questionType')}</p>
              <ScreeningTypesInfo />
            </div>
            {/* Dropdown wrapper */}
            <div className="relative flex flex-col w-full">
              <input
                type="hidden"
                {...register(`screening_questions.${index}.type`)}
                value={questionType}
              />
              <button
                ref={buttonRef}
                disabled={isDisabled}
                onClick={() => {
                  handleQuestionTypeSelect();
                  setIsFormChanged(true);
                }}
                type="button"
                className={`flex items-center justify-between w-full px-4 py-2 rounded-md border border-natural-700 max-w-60 ${
                  isDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto'
                } ${isQuestionTypeOpen ? 'rounded-b-none' : ''}`}
              >
                <div className="flex items-center gap-2 ">
                  {screeningQuestionTypeIcon[questionType as ScreeningQuestionType] as ReactNode}
                  <p>
                    {questionType === ScreeningQuestionType.MULTI_SELECT && 'Multi-select'}
                    {questionType === ScreeningQuestionType.SINGLE_SELECT && 'Single-select'}
                    {questionType === ScreeningQuestionType.NUMERIC && 'Numeric'}
                  </p>
                </div>
                <IconChevronDown size={16} />
              </button>
              {/* types */}
              <div
                ref={dropdownRef}
                className={cn(
                  'flex flex-col w-full max-w-60 z-100 absolute bg-white -bottom-[115px] rounded-b-md border-x border-natural-700 border-b overflow-hidden',
                  !isQuestionTypeOpen && 'hidden',
                )}
              >
                {screeningQuestionTypeOptions?.map((item) => (
                  <button
                    onClick={() => {
                      handleChangingQuestionType(item?.title);
                      handleQuestionTypeSelect();
                    }}
                    className={cn(
                      'flex items-center gap-2 px-4 py-2',
                      questionType === item?.title && 'bg-primary text-white',
                      questionType !== item?.title && 'hover:bg-gray-100',
                    )}
                    type="button"
                    key={item.id}
                  >
                    {item?.icon}
                    <p>
                      {item?.title === ScreeningQuestionType.MULTI_SELECT && 'Multi-select'}
                      {item?.title === ScreeningQuestionType.SINGLE_SELECT && 'Single-select'}
                      {item?.title === ScreeningQuestionType.NUMERIC && 'Numeric'}
                    </p>
                  </button>
                ))}
              </div>
            </div>

            {/* Answers */}
            <div className="flex flex-col w-full gap-2 pb-5 mt-5">
              {/* Multi-select type */}
              {questionType === ScreeningQuestionType.MULTI_SELECT && (
                <ScreeningMultiAndSingleSelectAnswer
                  questionId={id}
                  responses={responses as ScreeningResponse[]}
                  isProjectCompleted={isProjectCompleted}
                  isProjectPublished={isProjectPublished}
                  itemType={questionType}
                  handleQuestionAnswerChange={handleQuestionAnswerChange}
                  handleDeleteAnswer={handleDeleteAnswer}
                  handleAnswersOrderChange={handleAnswersOrderChange}
                  setIsFormChanged={setIsFormChanged}
                />
              )}

              {/* Single-select type */}
              {questionType === ScreeningQuestionType.SINGLE_SELECT && (
                <ScreeningMultiAndSingleSelectAnswer
                  questionId={id}
                  responses={responses as ScreeningResponse[]}
                  isProjectCompleted={isProjectCompleted}
                  isProjectPublished={isProjectPublished}
                  itemType={questionType}
                  handleQuestionAnswerChange={handleQuestionAnswerChange}
                  handleDeleteAnswer={handleDeleteAnswer}
                  handleAnswersOrderChange={handleAnswersOrderChange}
                  setIsFormChanged={setIsFormChanged}
                />
              )}

              {/* Numeric type */}
              {ScreeningQuestionType.NUMERIC === questionType && (
                <div className="flex flex-col w-full gap-5">
                  <div className="flex flex-col w-full gap-2">
                    <div className="flex items-center justify-between w-full gap-8">
                      <RequiredLabel title="minimum" notRequired isFullWidth>
                        <Input
                          disabled={isDisabled}
                          {...register(`screening_questions.${index}.numericResponse.minimum`, {
                            valueAsNumber: true,
                          })}
                          type="number"
                          placeholder="e.g. 2"
                          value={minimumValue}
                          className={validationError ? 'border-red-500' : ''}
                          onChange={(e) => {
                            setIsFormChanged(true);
                            const newMinValue = +e.target.value;
                            setMinimumValue(newMinValue);
                            const isError = !!maximumValue && newMinValue > +maximumValue;
                            setIsProjectCreateButtonDisabled(isError);
                            setValidationError(
                              isError ? 'Minimum value cannot be greater than maximum value' : null,
                            );

                            handleQuestionAnswerChange(id, '', {
                              minimum: newMinValue,
                            });
                          }}
                        />
                      </RequiredLabel>
                      <RequiredLabel title="maximum" notRequired isFullWidth>
                        <Input
                          disabled={isDisabled}
                          {...register(`screening_questions.${index}.numericResponse.maximum`, {
                            valueAsNumber: true,
                          })}
                          type="number"
                          placeholder="e.g. 4"
                          value={maximumValue}
                          className={validationError ? 'border-red-500' : ''}
                          onChange={(e) => {
                            setIsFormChanged(true);
                            const newMaxValue = +e.target.value;
                            setMaximumValue(newMaxValue);
                            const isError = !!minimumValue && +minimumValue > newMaxValue;
                            setIsProjectCreateButtonDisabled(isError);
                            setValidationError(
                              isError ? 'Maximum value cannot be less than minimum value' : null,
                            );
                            handleQuestionAnswerChange(id, '', {
                              maximum: newMaxValue,
                            });
                          }}
                        />
                      </RequiredLabel>
                    </div>
                    {validationError && (
                      <p className="text-red-500 font-semibold text-xs">{validationError}</p>
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* delete question */}
            <Button
              disabled={isDisabled}
              onClick={() => handleDeleteQuestion(id)}
              variant="destructive"
              className="self-end"
            >
              {lang.get('msg.deleteQuestion')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreeningQuestionBox;
