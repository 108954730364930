import { useRef, useState } from 'react';
import useOnClickOutside from 'chat-ui/src/hooks/useClickoutside';
import { ScreeningQuestionType, ScreeningRulesTypes } from '@/enums';
import { cn } from '@/lib/utils';
import {
  answerRulesListSingle,
  answerRulesListMulti,
  questionDescriptions,
} from './screeningRulesData';
import useTranslation from '@/hooks/useTranslation';

interface IScreeningRuleInfoProps {
  itemType: ScreeningQuestionType.MULTI_SELECT | ScreeningQuestionType.SINGLE_SELECT;
}

function Description({ type }: { type: ScreeningRulesTypes }) {
  const { lang } = useTranslation();
  return (
    <div className="flex flex-col w-full gap-3">
      <h4 className="font-semibold">{lang.get(`msg.${questionDescriptions[type].title}`)}</h4>
      <p className="text-xs font-medium">
        {lang.get(`msg.${questionDescriptions[type].description}`)}
      </p>
    </div>
  );
}

function ScreeningRulesInfo({ itemType }: IScreeningRuleInfoProps) {
  const { lang } = useTranslation();
  const popupRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showTypeDescriptionFor, setShowTypeDescriptionFor] = useState(ScreeningRulesTypes.QUALIFY);

  const rulesListByType =
    itemType === ScreeningQuestionType.SINGLE_SELECT ? answerRulesListSingle : answerRulesListMulti;

  const toggleInfo = () => setIsInfoOpen((prev) => !prev);

  useOnClickOutside(popupRef, (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setIsInfoOpen(false);
    }
  });

  return (
    <div className="relative">
      <button
        ref={buttonRef}
        onClick={toggleInfo}
        type="button"
        className="w-5 h-5 text-[13px] text-blue-600 bg-blue-200 rounded-full"
      >
        ?
      </button>

      {isInfoOpen && (
        <div
          ref={popupRef}
          className="flex absolute z-100 h-[250px] right-[430px] top-[25px] w-full"
        >
          <div className="flex flex-col p-6 bg-white min-w-[200px] border-y-2 border-l-2 border-black/20">
            <p className="font-semibold text-gray-400 uppercase whitespace-nowrap">
              {lang.get('msg.rules')}
            </p>
            <div className="flex flex-col items-start w-full gap-5 mt-5 font-semibold text-gray-400 whitespace-nowrap">
              {rulesListByType?.map((item) => (
                <button
                  key={item.id}
                  onClick={() => setShowTypeDescriptionFor(item.title)}
                  className={cn(
                    'flex items-center gap-2 transition-all hover:text-black',
                    showTypeDescriptionFor === item.title && 'text-black',
                  )}
                  type="button"
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
          <div className="flex flex-col p-6 bg-green-100 min-w-[250px] border-y-2 border-r-2 border-black/20">
            <Description type={showTypeDescriptionFor} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ScreeningRulesInfo;
