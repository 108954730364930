import { ChangeEvent, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { IconCrown, IconSend, IconShieldStar } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { DialogClose } from '@radix-ui/react-dialog';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '../ui/button';
import axiosInstance from '@/axios/axiosInstance';
import { EOrganizationRoles, EStatusCode } from '@/enums';
import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { getInitials } from '@/utils/stringHelpers';
import TooltipWrapper from '../tooltip/TooltipWrapper';
import { useMutateRequestPublishProject } from '@/reactQuery/post';
import { toast } from '../ui/use-toast';
import useTranslation from '@/hooks/useTranslation';
import useProjectRequiredFieldsStore from '@/store/ProjectRequiredFieldsStore';
import { OrgMembersMap, RequestPublishingModalProps } from '@/types';
import getHTMLTextOnPublishButtonHover from '@/utils/getHTMLTextOnPublishButtonHover';

function RequestPublishingModal({ is_status_draft, requestAgain }: RequestPublishingModalProps) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [requestPublishTooltipText, setRequestPublishTooltipText] = useState<string>('');
  const { project_id, organization_id } = useParams();
  const { mutateAsync: mutateAsyncRequestPublishProject } = useMutateRequestPublishProject();
  const { lang } = useTranslation();
  const { emptyFieldsLabels } = useProjectRequiredFieldsStore();

  const { data: organization_members } = useQuery({
    queryFn: () => axiosInstance.get(`organizations/get_members/${organization_id}`),
    queryKey: ['organization_members'],
    select: (data) => data.data,
  });

  const filteredOwnerAndAdmins = organization_members?.filter(
    (item: { role: string; email_verified: boolean; invite_accepted: boolean }) =>
      item?.email_verified &&
      item?.invite_accepted &&
      (item?.role === EOrganizationRoles.OWNER || item?.role === EOrganizationRoles.ADMIN),
  );

  const handleOptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Check if the option is already selected
    if (selectedOptions.includes(value)) {
      // If selected, remove it from the array
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      // If not selected, add it to the array
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const requestPublishingProjectFunction = async (formData: {
    project_id: string | undefined;
    selected_users: { [key: string]: string[] };
  }) => {
    try {
      await mutateAsyncRequestPublishProject(formData);
      toast({ description: lang.get('msg.organizationAdminsSuccessfullyInvited') });
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError?.status === EStatusCode.PROJECT_NOT_READY) {
        toast({
          variant: 'destructive',
          description: lang.get('msg.someOfRequiredFieldsAreEmpty'),
        });
      } else {
        toast({ variant: 'destructive', description: lang.get('errorPleaseTryAgain') });
      }
    }
  };

  useEffect(() => {
    setRequestPublishTooltipText(getHTMLTextOnPublishButtonHover(emptyFieldsLabels, lang));
  }, [emptyFieldsLabels, lang]);

  return (
    <Dialog>
      <TooltipWrapper
        text={requestPublishTooltipText}
        className={cn(
          is_status_draft ? 'flex' : 'hidden',
          'relative max-w-[320px] text-center mr-10',
        )}
      >
        <div>
          <DialogTrigger asChild>
            {!requestAgain ? (
              <Button disabled={is_status_draft} className="h-8 px-5">
                {lang.get('msg.requestToPublish')}
              </Button>
            ) : (
              <button
                type="button"
                className="text-xs transition-all ease-in-out hover:text-primary hover:underline whitespace-nowrap"
              >
                {lang.get('msg.requestApprovalAgain')}
              </button>
            )}
          </DialogTrigger>
        </div>
      </TooltipWrapper>
      <DialogContent className="max-w-xl z-[101]">
        <DialogHeader>
          <DialogTitle className="mb-1.5">
            {lang.get('msg.seekApprovalToPublishProject')}
          </DialogTitle>
          <DialogDescription>
            {lang.get('msg.requestPermissionFromTheOrganizations')} <b>{lang.get('msg.owner')}</b>{' '}
            {lang.get('msg.or')} <b>{lang.get('msg.admin')}</b>{' '}
            {lang.get('msg.toProceedWithPublishingTheProject')}.
          </DialogDescription>
        </DialogHeader>
        {/* content */}
        <div className="flex flex-col gap-2.5 mt-4 max-h-72 overflow-y-scroll p-1">
          {filteredOwnerAndAdmins?.map((item: OrgMembersMap) => (
            <label
              key={item._id}
              className={cn(
                'flex cursor-pointer items-center transition-all border-2 ease-in-out justify-between rounded-md ',
                selectedOptions.includes(item?.email) && 'border-primary/50 shadow',
              )}
            >
              <div className="flex items-center justify-between w-full h-full p-4">
                <div className="flex items-center gap-3">
                  <Avatar>
                    <AvatarImage src="" />
                    <AvatarFallback className="font-semibold border text-dark-text">
                      {getInitials(item?.name)}
                    </AvatarFallback>
                  </Avatar>
                  <div className="flex flex-col justify-center w-full">
                    <p className="flex items-center gap-1 text-base leading-5">
                      {item?.name}
                      {item?.role === EOrganizationRoles.OWNER && (
                        <IconCrown size={16} className="text-yellow-500" />
                      )}
                      {item?.role === EOrganizationRoles.ADMIN && (
                        <IconShieldStar size={15} className="text-gray-500" />
                      )}
                    </p>
                    <p className="text-sm">{item?.email}</p>
                  </div>
                </div>
                <input
                  type="checkbox"
                  value={item?.email}
                  checked={selectedOptions.includes(item?.email)}
                  onChange={handleOptionChange}
                  className="w-4 h-4"
                />
              </div>
            </label>
          ))}
        </div>
        <div className="flex items-center justify-end w-full mt-6">
          <DialogClose asChild>
            <Button
              variant="default"
              disabled={selectedOptions.length === 0}
              onClick={() =>
                requestPublishingProjectFunction({
                  project_id,
                  selected_users: {
                    emailsTo: selectedOptions,
                  },
                })
              }
              className="flex items-center gap-2"
            >
              <IconSend size={17} className="mt-px" /> {lang.get('msg.sendRequest')}
            </Button>
          </DialogClose>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default RequestPublishingModal;
