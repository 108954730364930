import { ScreeningRulesTypes } from '@/enums';

export const questionDescriptions: Record<
  ScreeningRulesTypes,
  { title: string; description: string }
> = {
  [ScreeningRulesTypes.QUALIFY]: {
    title: 'whenToUseQualify',
    description: 'whenToUseQualifyDescription',
  },
  [ScreeningRulesTypes.IRRELEVANT]: {
    title: 'whenToUseIrrelevant',
    description: 'whenToUseIrrelevantDescription',
  },
  [ScreeningRulesTypes.DISQUALIFY]: {
    title: 'whenToUseDisqualify',
    description: 'whenToUseDisqualifyDescription',
  },
};

export const answerRulesListMulti = [
  {
    id: 1,
    title: ScreeningRulesTypes.QUALIFY,
  },
  {
    id: 2,
    title: ScreeningRulesTypes.IRRELEVANT,
  },
  {
    id: 3,
    title: ScreeningRulesTypes.DISQUALIFY,
  },
];

export const answerRulesListSingle = [
  {
    id: 1,
    title: ScreeningRulesTypes.QUALIFY,
  },
  {
    id: 2,
    title: ScreeningRulesTypes.DISQUALIFY,
  },
];
