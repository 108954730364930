import { useQuery } from '@tanstack/react-query';
import axiosInstance from '@/axios/axiosInstance';
import axiosInstanceChat from '@/components/chat/axios/axiosInstanceChat';
import axiosInstanceAnalyze from '@/axios/AxiosInstanceAnalyze';

export const useGetRoles = () =>
  useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const { data } = await axiosInstance.get('/roles/get');
      return data;
    },
  });

export const useGetUserData = () =>
  useQuery<TUser>({
    queryKey: ['user'],
    queryFn: async () => {
      const { data } = await axiosInstance.get('/users/profile');
      return data;
    },
  });

export const useGetTranslationLanguages = (project_id) =>
  useQuery({
    queryKey: ['languages'],
    queryFn: async () => {
      const { data } = await axiosInstanceAnalyze.get(`analyzer/results/${project_id}/languages`);
      return data;
    },
    enabled: !!project_id,
  });

export const useChatHistory = (token: string | undefined) =>
  useQuery<TMessageData[]>({
    queryKey: ['chatHistory', token],
    enabled: !!token,
    retry: 1,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const { data } = await axiosInstanceChat.get('/test/history', {
        headers: { Authorization: `Bearer ${token}` },
      });
      return data;
    },
  });

const fetchTranslatedConversation = async ({ project_id, conversation_id, language }) => {
  const url = `analyzer/results/${project_id}/get_conversations/${conversation_id}`;
  const data = await axiosInstanceAnalyze.get(language ? `${url}?language=${language}` : url);
  return data;
};

export const useGetTranslatedConversations = (
  project_id: string | undefined,
  conversation_id: string,
  allConversations: { _id: string; conversation_id: string }[],
  activeLanguage: string | null,
  isTranslating: boolean,
  shouldFetch: boolean,
) => {
  const currentTranscriptIndex = allConversations?.findIndex(
    ({ _id, conversation_id: conv_id }) => conv_id === conversation_id || _id === conversation_id,
  );

  const prevTranscriptId =
    currentTranscriptIndex > 0
      ? allConversations[currentTranscriptIndex - 1]?.conversation_id ||
        allConversations[currentTranscriptIndex - 1]?._id
      : null;

  const nextTranscriptId =
    currentTranscriptIndex < allConversations.length - 1
      ? allConversations[currentTranscriptIndex + 1]?.conversation_id ||
        allConversations[currentTranscriptIndex + 1]?._id
      : null;

  return useQuery({
    queryKey: ['translatedConversations', conversation_id, activeLanguage],
    queryFn: async () => {
      const [current, prev, next] = await Promise.all([
        fetchTranslatedConversation({
          project_id,
          conversation_id,
          language: activeLanguage,
        }),
        prevTranscriptId
          ? fetchTranslatedConversation({
              project_id,
              conversation_id: prevTranscriptId,
              language: activeLanguage,
            })
          : Promise.resolve(null),
        nextTranscriptId
          ? fetchTranslatedConversation({
              project_id,
              conversation_id: nextTranscriptId,
              language: activeLanguage,
            })
          : Promise.resolve(null),
      ]);

      return { current, prev, next };
    },
    enabled: !!conversation_id && currentTranscriptIndex >= 0 && !isTranslating && shouldFetch,
  });
};

export default useGetRoles;
