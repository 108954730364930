import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconChevronDown, IconGripVertical, IconTrash } from '@tabler/icons-react';
import { useLayoutEffect, useRef, useState, useEffect } from 'react';
import useOnClickOutside from 'chat-ui/src/hooks/useClickoutside';
import { Input } from '@/components/ui/input';
import { cn } from '@/lib/utils';
import ScreeningRulesInfo from './ScreeningRulesInfo';
import { answerRulesListMulti, answerRulesListSingle } from './screeningRulesData';
import useCreateProjectStore from '@/store/CreateProjectStore';
import { ScreeningMultiItemProps } from '@/types';
import { ScreeningQuestionType, ScreeningRulesTypes } from '@/enums';
import useTranslation from '@/hooks/useTranslation';

function ScreeningMultiAndSingleSelectItem({
  isActiveDrag,
  id,
  questionId,
  openQuestionId,
  setOpenQuestionId,
  isUserViewer,
  isProjectCompleted,
  isProjectPublished,
  answer,
  index,
  handleDeleteQuestion,
  handleQuestionAnswerChange,
  itemType,
  totalNumberOfAnswers,
}: ScreeningMultiItemProps) {
  const { lang } = useTranslation();
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [currentAnswerRule, setCurrentAnswerRule] = useState(answer?.rule);
  const [currentAnswerText, setCurrentAnswerText] = useState(answer?.value);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { setIsFormChanged, isFormChanged } = useCreateProjectStore();
  const rulesListByType =
    itemType === ScreeningQuestionType.SINGLE_SELECT ? answerRulesListSingle : answerRulesListMulti;

  const handleAnswerRuleState = () => {
    setIsRulesOpen(!isRulesOpen);
  };

  const handleAnswerChange = (newValue: string) => {
    setCurrentAnswerText(newValue);
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  const handleChangingQuestionType = (type: ScreeningRulesTypes) => {
    setCurrentAnswerRule(type);
    setIsFormChanged(true);
  };

  useEffect(() => {
    handleQuestionAnswerChange(questionId, id, currentAnswerText, currentAnswerRule);
  }, [currentAnswerText, currentAnswerRule]);

  const isOpen = openQuestionId === id;

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: isDragging ? '2px dashed #718096' : '1px solid #e2e8f0',
    opacity: isDragging ? 0.7 : 1,
    boxShadow: isDragging ? '0 4px 6px rgba(0, 0, 0, 0.4)' : 'none',
  };

  useLayoutEffect(() => {
    if (isActiveDrag) {
      setOpenQuestionId(null);
    }
  }, [isActiveDrag, setOpenQuestionId]);

  useOnClickOutside(dropdownRef, (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
      setIsRulesOpen(false);
    }
  });

  const isDisabled = isUserViewer || isProjectCompleted || isProjectPublished;
  const removeButtonIsDisabled = totalNumberOfAnswers === 2 || isDisabled;
  const canDrag = !isUserViewer && !isProjectCompleted && !isProjectPublished;

  return (
    <div className="flex flex-col gap-2">
      {/* titles */}
      {index === 0 && (
        <div className="flex items-center justify-between w-full">
          <p className="self-start font-medium">{lang.get('msg.answers')}</p>
          <div className="self-start flex justify-between items-center font-medium w-[205px]">
            <p>{lang.get('msg.rules')}</p>
            <ScreeningRulesInfo itemType={itemType} />
          </div>
        </div>
      )}

      <div
        ref={setNodeRef}
        style={style}
        className="flex items-center w-full gap-4 p-4 bg-white border rounded-md shadow"
      >
        {/* drag item */}
        {canDrag && (
          <span className={`${!isOpen ? 'flex' : 'hidden'}`} {...attributes} {...listeners}>
            <IconGripVertical size={24} className="relative z-50 text-light-gray cursor-grabbing" />
          </span>
        )}

        {/* asnwer */}
        <Input
          type="text"
          placeholder={lang.get('msg.screeningAnswersPlaceholder')}
          value={currentAnswerText as string}
          disabled={isDisabled}
          onChange={(e) => handleAnswerChange(e.target.value)}
        />

        <div
          className={`flex flex-col ${
            isDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto'
          }`}
        >
          {/* Rules dropdown wrapper */}
          <div className="relative flex flex-col w-full">
            <button
              ref={buttonRef}
              onClick={handleAnswerRuleState}
              type="button"
              className={`flex items-center justify-between w-full px-4 py-2 rounded-md border border-natural-700 max-w-60  ${
                isDisabled ? 'pointer-events-none opacity-50' : 'pointer-events-auto'
              } ${isRulesOpen ? 'rounded-b-none' : ''}`}
            >
              <div className="flex items-center w-24 gap-2">
                <p>{currentAnswerRule}</p>
              </div>
              <IconChevronDown size={16} />
            </button>
            {/* -- Rules -- */}
            <div
              ref={dropdownRef}
              className={cn(
                'flex flex-col w-full max-w-60 z-100 absolute bg-white border-x border-natural-700 border-t-0 border-b-2 rounded-b-md overflow-hidden',
                !isRulesOpen && 'hidden',
                itemType === ScreeningQuestionType.SINGLE_SELECT && '-bottom-[76px]',
                itemType === ScreeningQuestionType.MULTI_SELECT && '-bottom-[115px]',
              )}
            >
              {rulesListByType?.map((item) => (
                <button
                  onClick={() => {
                    handleChangingQuestionType(item?.title);
                    handleAnswerRuleState();
                  }}
                  className={cn(
                    'flex items-center gap-2 px-4 py-2',
                    currentAnswerRule === item?.title && 'bg-primary text-white',
                    currentAnswerRule !== item?.title && 'hover:bg-gray-100',
                  )}
                  type="button"
                  key={item.id}
                >
                  <p>{item?.title}</p>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* remove answer */}
        <button
          disabled={removeButtonIsDisabled}
          type="button"
          className={`ml-2 ${
            removeButtonIsDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }`}
          onClick={() => handleDeleteQuestion(id)}
        >
          <IconTrash size={18} />
        </button>
      </div>
    </div>
  );
}

export default ScreeningMultiAndSingleSelectItem;
